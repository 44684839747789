import { faCog, faHomeLgAlt, faUser } from '@fortawesome/pro-light-svg-icons'
import { ConnectedProps, connect } from 'react-redux'
import AppLayout from '../../baseComponents/AppLayout'
import CreateRoutering from '../../baseComponents/CreateRoutering'
import { cleanRoutes } from '../../baseComponents/CreateRoutering/functions'
import { Routes } from '../../baseComponents/CreateRoutering/types'
import useTwinTranslation from '../../utils/hooks/useTwinTranslation'
import { AllReduxAppPayloads, GlobalContext } from '../../utils/reducers'
import ErpLoader from './components/ErpLoader'
import ActivateFingerprintInner from './screens/ActivateFingerprintInner'
import EmployeeProfile from './screens/BottomIcons/EmployeeProfile'
import Notifications from './screens/BottomIcons/Notifications'
import UserTimeControl from './screens/BottomIcons/UserTimeControl'
import CompanyDashboard from './screens/Company/CompanyDashboard'
import WorkingCenters from './screens/Company/CompanyParent/WorkingCenters'
import SingleWorkingCenter from './screens/Company/CompanyParent/WorkingCenters/SingleWorkingCenter'
import Sues from './screens/Company/Sues'
import ConfigParameters from './screens/Config/ConfigParameters'
import Employees from './screens/Staff/EmployeeParent/Employees'
import SingleEmployee from './screens/Staff/EmployeeParent/Employees/SingleEmployee'
import GroupEmployees from './screens/Staff/EmployeeParent/GroupEmployees'
import PermissionGroup from './screens/Staff/EmployeeParent/GroupEmployees/PermissionsGroup'
import StaffDashboard from './screens/Staff/StaffDashboard'
import EmployeeAbsencesRequests from './screens/Staff/WorkingCalendarParent/EmployeeAbsencesRequests'
import EmployeeAbsenceTypes from './screens/Staff/WorkingCalendarParent/EmployeeAbsencesTypes'
import TimeControlEmployeeMonth from './screens/Staff/WorkingCalendarParent/TimeControlEmployeeMonth'
import WorkingCalendarFree from './screens/Staff/WorkingCalendarParent/WorkingCalendarFree'
import WorkingCalendarFreeDays from './screens/Staff/WorkingCalendarParent/WorkingCalendarFree/WorkingCalendarFreeDays'
import WorkingCalendarHolidayBag from './screens/Staff/WorkingCalendarParent/WorkingCalendarHolidayBag'
import WorkingCalendarHolidayRequests from './screens/Staff/WorkingCalendarParent/WorkingCalendarHolidayRequests'
import WorkingCalendarSpecial from './screens/Staff/WorkingCalendarParent/WorkingCalendarSpecial'
import WorkingCalendarSpecialDays from './screens/Staff/WorkingCalendarParent/WorkingCalendarSpecial/WorkingCalendarSpecialDays'
import WorkingCalendars from './screens/Staff/WorkingCalendarParent/WorkingCalendars'
import SingleWorkingCalendar from './screens/Staff/WorkingCalendarParent/WorkingCalendars/SingleWorkingCalendar'
import CustomerProjectTypes from './screens/Company/CustomerParent/CustomerProjectTypes'
import Customers from './screens/Company/CustomerParent/Customers'
import SingleCustomer from './screens/Company/CustomerParent/Customers/SingleCustomer'
import CustomersB2B from './screens/Company/CustomerParent/CustomersB2B'
import SingleCustomerB2B from './screens/Company/CustomerParent/CustomersB2B/SingleCustomerB2B'
import GroupsCustomers from './screens/Company/CustomerParent/GroupsCustomers'
import TaskCustomFields from './screens/Company/Tasks/TaskCustomFields'
import TaskTypes from './screens/Company/Tasks/TaskTypes'
import TaskBagOfHours from './screens/Company/Tasks/TaskBagOfHours'
import TaskCalendarAdmin from './screens/Company/Tasks/TaskCalendarAdmin'
import Contacts from './screens/Company/CustomerParent/Contacts'
import SingleContact from './screens/Company/CustomerParent/Contacts/SingleContact'
import CustomerProjectFlow from './screens/Company/CustomerParent/CustomerProjectFlow/CustomerProjectFlowProps'
import CustomerProjectTypesStatusesFlow from './screens/Company/CustomerParent/CustomerProjectFlow'
import TasksSummary from './screens/Company/Tasks/TasksSummary'
import TasksByEmployee from './screens/Company/Tasks/TasksTableEmployees/TasksByEmployee'
import TasksTableGroups from './screens/Company/Tasks/TasksTableGroups'
import TaskByGroup from './screens/Company/Tasks/TasksTableGroups/TaskByGroup'
import TasksTableEmployees from './screens/Company/Tasks/TasksTableEmployees'
import UsersLogs from './screens/Company/CompanyParent/UsersLogs'
import ConfigCurrencies from './screens/Config/ConfigCurrencies'
import ExpensesRequest from './screens/Staff/ExpensesParent/ExpensesRequest'
import ExpensesTypes from './screens/Staff/ExpensesParent/ExpensesTypes'
import CustomerProjects from './screens/Company/CustomerParent/CustomerProjects'
import ConfigLangs from './screens/Config/ConfigLangs'
import TaskRespositories from './screens/Company/Tasks/TaskRepositories'
import ConfigTemplateChecklists from './screens/Config/ConfigTemplateChecklists'
import CustomerOrigins from './screens/Company/CustomerParent/CustomerOrigins'

export const ErpRoutesPaths = (EmployeeId?: number): Routes => {
    const { t } = useTwinTranslation()
    const now = new Date()
    return [
        {
            link: '/',
            text: t('main', 'Principal'),
            icon: faHomeLgAlt,
            component: CompanyDashboard,
            childs: [
                {
                    link: '/company',
                    text: t('company', 'Empresa'),
                    component: CompanyDashboard,
                    childs: [
                        {
                            link: '/dashboard',
                            text: t('company', 'Empresa'),
                            component: CompanyDashboard
                        },
                        {
                            link: '/workingCenters',
                            text: t('workingCenters', 'Centros de trabajo'),
                            component: WorkingCenters,
                            permissions: 'workingCenter.main',
                            childs: [
                                {
                                    link: '/:id/:tab',
                                    text: t('workingCenter', 'Centro de trabajo'),
                                    component: SingleWorkingCenter,
                                    permissions: ['workingCenter', 'workingCenter.employee', 'workingCenter.main']
                                }
                            ]
                        },
                        {
                            link: '/userLogs',
                            text: t('userLogs', 'Registro de usuarios'),
                            component: UsersLogs,
                            permissions: 'logs',
                        }
                    ]
                },
                {
                    link: '/customer',
                    text: t('customers', 'Clientes'),
                    modules: ['tasks'],
                    childs: [
                        {
                            link: '/customers',
                            text: t('customers', 'Clientes'),
                            component: Customers,
                            permissions: 'customer.profile.main',
                            modules: ['tasks'],
                            childs: [
                                {
                                    link: '/:id/:tab',
                                    text: t('customer', 'Cliente'),
                                    component: SingleCustomer,
                                    permissions: ['customer.profile.main', 'customer.profile.address', 'customer.profile.bank', 'customer.profile.contactPerson', 'customer.profile.files', 'customer.profile.projects', 'customer.profile.shops', 'customer.profile.bagOfHours', 'customer.profile.gdpr', 'customer.profile.tasks'],
                                }
                            ]
                        },
                        {
                            link: '/customersB2B',
                            text: t('customersB2B', 'Clientes B2B'),
                            component: CustomersB2B,
                            permissions: 'customer.profile.main',
                            modules: ['tasks'],
                            childs: [
                                {
                                    link: '/:id/:tab',
                                    text: t('customerB2B', 'Cliente B2B'),
                                    component: SingleCustomerB2B,
                                    permissions: ['customer.profile.main', 'customer.profile.address', 'customer.profile.bank', 'customer.profile.contactPerson', 'customer.profile.files', 'customer.profile.projects', 'customer.profile.shops', 'customer.profile.bagOfHours', 'customer.profile.gdpr', 'customer.profile.tasks']
                                }
                            ]
                        },
                        {
                            link: '/contacts',
                            text: t('leads', 'Leads'),
                            component: Contacts,
                            permissions: 'customer.profile.main',
                            modules: ['tasks'],
                            childs: [
                                {
                                    link: '/:id/:tab',
                                    text: t('lead', 'Lead'),
                                    component: SingleContact,
                                    permissions: ['customer.profile.main', 'customer.profile.address', 'customer.profile.bank', 'customer.profile.contactPerson', 'customer.profile.files', 'customer.profile.projects', 'customer.profile.shops', 'customer.profile.bagOfHours', 'customer.profile.gdpr', 'customer.profile.tasks']
                                }
                            ]
                        },
                        {
                            link: '/groups',
                            text: t('groupsCustomers', 'Grupos de clientes'),
                            component: GroupsCustomers,
                            permissions: 'customer.customerGroup',
                            modules: ['tasks']
                        },
                        {
                            link: '/origins',
                            text: t('customerOrigins', 'Orígenes de cliente'),
                            component: CustomerOrigins,
                            permissions: 'customer.origin',
                        },
                        {
                            link: '/projectTypes/:CustomerProjectTypeId',
                            text: t('customerProjectTypes', 'Tipos de proyecto'),
                            component: CustomerProjectTypes,
                            permissions: 'customer.customerProjectType',
                            to: '/projectTypes/main',
                            modules: ['tasks']
                        },
                        {
                            link: '/projectStatusFlow',
                            text: t('statusFlow', 'Flujo de estados'),
                            component: CustomerProjectTypesStatusesFlow,
                            permissions: 'customer.projects.main',
                            modules: ['tasks'],
                            childs: [
                                {
                                    link: '/:id/:tab',
                                    text: t('projects', 'Proyectos'),
                                    component: CustomerProjectFlow,
                                    permissions: 'customer.projects.main'
                                }
                            ]
                        },
                        {
                            link: '/customerProjects',
                            text: t('projects', 'Proyectos'),
                            component: CustomerProjects,
                            permissions: 'customer.projects.main',
                            modules: ['tasks'],
                        },
                    ]
                },
                {
                    link: '/sue',
                    text: t('sues', 'Denuncias'),
                    component: Sues,
                    permissions: 'sue',
                    modules: ['sue'],
                    childs: [
                        {
                            link: '/sues',
                            text: t('sues', 'Denuncias'),
                            component: Sues,
                            permissions: 'sue',
                        },
                    ]
                },
                {
                    link: '/task',
                    text: t('tasks', 'Tareas'),
                    component: TasksSummary,
                    permissions: 'task.main',
                    modules: ['tasks'],
                    childs: [
                        {
                            link: '/tasks/:id',
                            text: t('tasks', 'Tareas'),
                            component: TasksSummary,
                            permissions: 'task.main',
                            to: '/tasks/main'
                        },
                        {
                            link:`/myTask/:EmployeeId/:id`,
                            text: t('myTasks', 'Mis Tareas'),
                            component: TasksByEmployee,
                            permissions: 'task.main',
                            to: `/myTask/${EmployeeId}/main`,
                        },
                        {
                            link: '/employees',
                            text: t('employeeTasks', 'Tareas del empleado'),
                            component: TasksTableEmployees,
                            permissions: 'task.main',
                            childs: [
                                {
                                    link: '/:EmployeeId/:id',
                                    text: t('employeeTasks', 'Tareas del empleado'),
                                    component: TasksByEmployee,
                                    permissions: 'task.main',
                                }
                            ]
                        },
                        {
                            link: '/groups',
                            text: t('groupTasks', 'Tareas por equipo'),
                            component: TasksTableGroups,
                            permissions: 'task.main',
                            childs: [
                                {
                                    link: '/:GroupId/:id',
                                    text: t('groupTasks', 'Tareas del Equipo'),
                                    component: TaskByGroup,
                                    permissions: 'task.main',
                                }
                            ]
                        },
                        {
                            link: '/customFields/:TaskCFieldId',
                            text: t('customFields', 'Campos personalizados'),
                            component: TaskCustomFields,
                            permissions: 'task.CField',
                            to: '/customFields/main'
                        },
                        {
                            link: '/taskTypes',
                            text: t('taskTypes', 'Tipos de tarea'),
                            component: TaskTypes,
                            permissions: 'task.taskType',
                        },
                        {
                            link: '/taskBagOfHours',
                            text: t('bagsOfHours', 'Bolsas de horas'),
                            component: TaskBagOfHours,
                            permissions: 'task.bagOfHours'
                        },
                        {
                            link: '/taskCalendar',
                            text: t('taskCalendar', 'Calendario de tareas'),
                            component: TaskCalendarAdmin,
                            permissions: 'task.main'
                        },
                        {
                            link: '/taskRepositories',
                            text: t('repository', 'Repositorio'),
                            component: TaskRespositories,
                            permissions: 'task.repository',
                        },
                    ]
                },
            ]
        },
        {
            link: '/staff',
            text: t('staff', 'Personal'),
            icon: faUser,
            component: StaffDashboard,
            childs: [
                {
                    link: '/people',
                    text: t('employees', 'Empleados'),
                    childs: [
                        {
                            link: '/employees',
                            text: t('employees', 'Empleados'),
                            component: Employees,
                            permissions: 'employee.main',
                            childs: [
                                {
                                    link: '/:id/:tab',
                                    text: t('employee', 'Empleado'),
                                    component: SingleEmployee,
                                    permissions: ['employee.main', 'employee.files', 'employee.holidays', 'employee.absences', 'employee.payslip', 'employee.expenses']
                                }
                            ]
                        },
                        {
                            link: '/groups',
                            text: t('employeesGroups', 'Grupos de Empleados'),
                            component: GroupEmployees,
                            permissions: 'group.main',
                            childs: [
                                {
                                    link: '/:id',
                                    text: t('permission', 'Permisos'),
                                    component: PermissionGroup,
                                    permissions: ['group.permissions']
                                }
                            ]
                        },
                    ]
                },
                {
                    link: '/timeControl',
                    text: t('timeControl', 'Control horario'),
                    modules: ['working_calendar', 'working_calendar_medium', 'working_calendar_full'],
                    childs: [
                        {
                            link: '/controlEmployeesWorkingMonth/:EmployeeId/:month/:year',
                            to: `/controlEmployeesWorkingMonth/${EmployeeId}/${now.getMonth() + 1}/${now.getFullYear()}`,
                            text: t('controlWorkingMonth', 'Control calendario del mes'),
                            component: TimeControlEmployeeMonth,
                            permissions: 'WCal.checkEmployeeMonth'
                        },
                        {
                            link: '/workingCalendarHolidayRequests',
                            text: t('holidayListing', 'Listado de vacaciones'),
                            component: WorkingCalendarHolidayRequests,
                            permissions: 'WCal.holiday.usedDays',
                            modules: ['working_calendar_medium', 'working_calendar_full']
                        },
                        {
                            link: '/employeeAbsencesRequests',
                            text: t('absencesListing', 'Listado de ausencias'),
                            component: EmployeeAbsencesRequests,
                            permissions: 'WCal.absences.control',
                            modules: ['working_calendar_full']
                        },
                        {
                            link: '/workingCalendarFree',
                            text: t('workingCalendarsFree', 'Calendarios de días festivos'),
                            component: WorkingCalendarFree,
                            permissions: 'WCal.free',
                            childs: [
                                {
                                    link: '/:id/:year',
                                    text: t('freeDays', 'Días festivos'),
                                    component: WorkingCalendarFreeDays,
                                    permissions: 'WCal.free'
                                }
                            ]
                        },
                        {
                            link: '/workingCalendarSpecialDays',
                            text: t('workingCalendarSpecialDays', 'Calendarios de días especiales'),
                            component: WorkingCalendarSpecial,
                            permissions: 'WCal.special',
                            modules: ['working_calendar_full'],
                            childs: [
                                {
                                    link: '/:id/:year',
                                    text: t('specialDays', 'Días especiales'),
                                    component: WorkingCalendarSpecialDays,
                                    permissions: 'WCal.special'
                                }
                            ]
                        },
                        {
                            link: '/workingCalendarHolidayBag',
                            text: t('holidayBag', 'Bolsa de vacaciones'),
                            component: WorkingCalendarHolidayBag,
                            permissions: 'WCal.holiday.bags',
                            modules: ['working_calendar_medium', 'working_calendar_full']
                        },
                        {
                            link: '/workingCalendars',
                            text: t('workingCalendars', 'Calendarios laborales'),
                            component: WorkingCalendars,
                            permissions: 'WCal.main',
                            childs: [
                                {
                                    link: '/:id/:tab',
                                    text: t('workingCalendar', 'Calendario laboral'),
                                    component: SingleWorkingCalendar,
                                    permissions: ['WCal.main', 'WCal.schedule']
                                }
                            ]
                        },
                        {
                            link: '/employeeAbsenceTypes',
                            text: t('employeeAbsenceTypes', 'Tipos de ausencia'),
                            component: EmployeeAbsenceTypes,
                            permissions: 'WCal.absences.type',
                            modules: ['working_calendar_full']
                        },
                    ]
                },
                {
                    link: '/expenses',
                    text: t('expenses', 'Gastos'), modules: ['employee_expenses'],
                    childs: [
                        {
                            link: '/expensesRequests',
                            text: t('expensesListing', 'Listado de gastos'),
                            component: ExpensesRequest,
                            permissions: 'expenses.main',
                        },
                        {
                            link: '/expensesTypes',
                            text: t('expensesTypes', 'Tipos de gastos'),
                            component: ExpensesTypes,
                            permissions: 'expenses.rowType'
                        },
                    ]
                },
            ]
        },
        {
            link: '/config',
            text: t('config', 'Configuración'),
            icon: faCog,
            childs: [
                {
                    link: '/app',
                    text: t('appConfig', 'Configuración de aplicación'),
                    childs: [
                        {
                            link: '/parameters/:tab',
                            to: '/parameters/main',
                            text: t('parameters', 'Parámetros'),
                            component: ConfigParameters,
                            permissions: ['config.parameter']
                        },
                        {
                            link: '/currency',
                            text: t('currency', 'Moneda'),
                            component: ConfigCurrencies,
                            permissions: 'config.currency'
                        },
                        {
                            link: '/lang',
                            text: t('language', 'Idioma'),
                            component: ConfigLangs,
                            permissions: 'config.lang'
                        },
                        {
                            link: '/templateChecklist',
                            text: t('templateList', 'Plantilla lista'),
                            component: ConfigTemplateChecklists,
                            permissions: 'config.customerProject.templateChecklist'
                        },
                    ]
                }
            ]
        },
        {
            link: '/employeeProfile/:tab',
            text: t('employee', 'Empleado'),
            component: EmployeeProfile,
        },
        {
            link: '/timeControl/:tab',
            text: t('timeControl', 'Control horario'),
            component: UserTimeControl,
            modules: ['working_calendar', 'working_calendar_medium', 'working_calendar_full'],
        },
        {
            link: '/notifications/:tab',
            text: t('notifcations', 'Notificaciones'),
            component: Notifications,
        },
        {
            link: '/activateDevice/:fingerprint_token',
            text: 'Activate',
            component: ActivateFingerprintInner
        }
    ]
}

export interface ErpRoutesProps extends ReduxErpRoutesConnector {

};

const ErpRoutes: React.FC<ErpRoutesProps> = ({ groupPermissions, modulesBuyed, modulesDemo, EmployeeId }) => {
    const routes = ErpRoutesPaths(EmployeeId)
    const routesClean = cleanRoutes(routes, groupPermissions, modulesBuyed, modulesDemo)
    return <AppLayout routes={routesClean}><CreateRoutering routes={routesClean}></CreateRoutering></AppLayout>
}

const mapErpRoutesConnector = (state: AllReduxAppPayloads) => ({ groupPermissions: state.user?.extraData.groupPermissions, modulesBuyed: state.company?.modulesBuyed, modulesDemo: state.company?.modulesDemo, EmployeeId: state?.user?.extraData?.Employee?.id })
export const erpRoutesConnector = connect(mapErpRoutesConnector, null, null, { context: GlobalContext })
export type ReduxErpRoutesConnector = ConnectedProps<typeof erpRoutesConnector>


const ErpRoutesConnector = erpRoutesConnector(ErpRoutes)

const ErpRoutesLoader: React.FC = () => {
    return (<ErpLoader><ErpRoutesConnector /></ErpLoader>)
}

export default ErpRoutesLoader