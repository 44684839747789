import { faUser, faUserGroup, faBriefcaseBlank, faArrowTurnDownRight, faComment, faXmark, faUpFromLine } from '@fortawesome/pro-light-svg-icons'
import TwinIcon, { TwinIconProp } from '../../../../baseComponents/TwinIcon'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { CustomerProjectTabsInfoHeaderProps, CustomerProjectTabsInfoHeader, CustomerProjectTabsCommentBox } from '../CustomerProjectComponents'
import { CustomerProjectModelTypeExtended, CustomerProjectActivityDataTy, CustomerProjectActivityTy, CustomerProjectCommentModelTypeExt } from '../types'

interface CustomerProjectActivityTabProps {
    customerProjectData: CustomerProjectModelTypeExtended
    getCustomerProject: () => Promise<void>
}

const CustomerProjectActivityTab: React.FC<CustomerProjectActivityTabProps> = ({ customerProjectData, getCustomerProject }) => {
    const renderThis: JSX.Element[] = []
    const customerProjectActivityData: CustomerProjectActivityDataTy[] = []

    const customerProjectComments = customerProjectData?.CustomerProjectComments || []
    for (const element of customerProjectComments) {
        customerProjectActivityData.push({
            type: 'comment',
            data: element,
            date: element.createdAt
        })
    }

    const customerProjectStatusHistories = customerProjectData?.CustomerProjectStatusHistories || []
    for (const element of customerProjectStatusHistories) {
        customerProjectActivityData.push({
            type: 'status',
            data: element,
            date: element.createdAt
        })
    }
    const customerProjectEmployees = customerProjectData?.CustomerProjectEmployeeLogs || []
    for (const element of customerProjectEmployees) {
        customerProjectActivityData.push({
            type: element.deleted ? 'deletedEmployee' : 'assignedEmployee',
            data: element,
            date: element.createdAt || ''
        })
    }

    const customerProjectGroups = customerProjectData?.CustomerProjectGroupLogs || []
    for (const element of customerProjectGroups) {
        customerProjectActivityData.push({
            type: element.deleted ? 'deletedGroup' : 'assignedGroup',
            data: element,
            date: element.createdAt || ''
        })
    }
    const customerProjectChangedTypes = customerProjectData?.CustomerProjectChangedTypeLogs || []
    for (const element of customerProjectChangedTypes) {
        customerProjectActivityData.push({
            type: 'changedType',
            data: element,
            date: element.createdAt || ''
        })
    }

    const customerProjectActives = customerProjectData?.CustomerProjectActiveLogs || []
    for (const element of customerProjectActives) {
        customerProjectActivityData.push({
            type: element.active ? 'reactivatedProject' : 'disabledProject',
            data: element,
            date: element.createdAt || ''
        })
    }

    customerProjectActivityData.sort((a, b) => {
        return new Date(b.date) > new Date(a.date) ? 1 : -1
    })

    customerProjectActivityData.push({
        type: 'createdAt',
        data: {},
        date: customerProjectData.createdAt
    })

    for (const key in customerProjectActivityData) {
        const element = customerProjectActivityData[key]
        renderThis.push(<CustomerProjectActivityItem item={element} getData={getCustomerProject} key={key} />)
    }
    return (
        <div className='grid mt-25 flex-auto overflow-auto customer_project_activity_tab'>
            <div className='customer_project_activity_line_parent'>
                <div className='customer_project_activity_line'></div>
            </div>
            <div className='flex flex-col flex-auto gap-20 customer_project_activity_tab_inner'>
                {renderThis}
            </div>
        </div>
    )
}

interface CustomerProjectActivityItemProps {
    item: CustomerProjectActivityDataTy
    getData: () => Promise<void>
}

const CustomerProjectActivityItem: React.FC<CustomerProjectActivityItemProps> = ({ item, ...rest }) => {
    const componentsTypeDict: Record<CustomerProjectActivityTy, React.FC<CustomerProjectComponentProps>> = {
        'createdAt': CustomerProjectCreatedProjectComponent,
        'comment': CustomerProjectCommentComponent,
        'status': CustomerProjectHistoryComponent,
        'assignedEmployee': CustomerProjectEmployeeComponent,
        'assignedGroup': CustomerProjectGroupComponent,
        'deletedEmployee': CustomerProjectEmployeeDeletedComponent,
        'deletedGroup': CustomerProjectGroupDeletedComponent,
        'changedType': CustomerProjectChangedTypeComponent,
        'disabledProject': CustomerProjectDisabledComponent,
        'reactivatedProject' : CustomerProjectReactiveComponent
    }
    const Component = componentsTypeDict[item.type]
    return (
        <Component item={item} {...rest} />
    )
}

interface CustomerProjectComponentProps {
    item: CustomerProjectActivityDataTy
    getData: () => Promise<void>
}

const CustomerProjectEmployeeComponent: React.FC<CustomerProjectComponentProps> = ({ item }) => {
    const { t } = useTwinTranslation()
    return (
        <CustomerProjectActivityComponent id={item.data.EmployeeTriggerId} icon={faUser} headerText={t('assignedToProject', 'Asignado al proyecto')} image={item.data.EmployeeTrigger.profile_image} date={item.date} secondText={item.data.Employee.name} nameTrigger={item.data.EmployeeTrigger.fullname_short} tooltipName={'assignedEmployeeActivityTab'} />
    )
}
const CustomerProjectGroupComponent: React.FC<CustomerProjectComponentProps> = ({ item }) => {
    const { t } = useTwinTranslation()
    return (
        <CustomerProjectActivityComponent id={item.data.EmployeeTriggerId} icon={faUserGroup} headerText={t('assignedToProject', 'Asignado al proyecto')} image={item.data.EmployeeTrigger.profile_image} date={item.date} secondText={item.data.Group.name} nameTrigger={item.data.EmployeeTrigger.fullname_short} tooltipName={'assignedGroupActivityTab'} />
    )
}
const CustomerProjectEmployeeDeletedComponent: React.FC<CustomerProjectComponentProps> = ({ item }) => {
    const { t } = useTwinTranslation()
    return (
        <CustomerProjectActivityComponent id={item.data.EmployeeTriggerId} icon={faUser} headerText={t('removedFromProject', 'Eliminado del proyecto')} image={item.data.EmployeeTrigger.profile_image} date={item.date} secondText={item.data.Employee.name} nameTrigger={item.data.EmployeeTrigger.fullname_short} tooltipName={'removedEmployeeActivityTab'} />
    )
}
const CustomerProjectGroupDeletedComponent: React.FC<CustomerProjectComponentProps> = ({ item }) => {
    const { t } = useTwinTranslation()
    return (
        <CustomerProjectActivityComponent id={item.data.EmployeeTriggerId} icon={faUserGroup} headerText={t('removedFromProject', 'Eliminado del proyecto')} image={item.data.EmployeeTrigger.profile_image} date={item.date} secondText={item.data.Group.name} nameTrigger={item.data.EmployeeTrigger.fullname_short} tooltipName={'removedGroupActivityTab'} />
    )
}

const CustomerProjectCreatedProjectComponent: React.FC<CustomerProjectComponentProps> = ({ item }) => {
    const { t } = useTwinTranslation()
    return (
        <CustomerProjectActivityComponent nameTrigger='' icon={faBriefcaseBlank} headerText={t('projectCreation', 'Creación del proyecto')} date={item.date} showImage={false} tooltipName='creationProject' />
    )
}

const CustomerProjectHistoryComponent: React.FC<CustomerProjectComponentProps> = ({ item }) => {
    const { t } = useTwinTranslation()
    return (
        <CustomerProjectActivityComponent id={item.data.EmployeeId} icon={faArrowTurnDownRight} mainColor={item?.data?.CustomerProjectStatus?.color} headerText={t('changedStatusToSm', 'Cambio de estado a')} image={item?.data?.Employee?.profile_image} date={item.data.createdAt} secondText={item?.data?.CustomerProjectStatus?.name} tooltipName={'statusChangedEmployeeActivityTab'} nameTrigger={item?.data?.Employee?.fullname_short} />
    )
}

const CustomerProjectCommentComponent: React.FC<CustomerProjectComponentProps> = ({ item, getData }) => {
    const { t } = useTwinTranslation()
    return (
        <CustomerProjectActivityComponent id={item.data.Employee.id} icon={faComment} tooltipName={'commentEmployeeActivityTab'} headerText={t('newComment', 'Nuevo comentario')} image={item.data.Employee?.profile_image} date={item.date} nameTrigger={item.data.Employee.fullname_short} >
            <CustomerProjectTabsCommentBox data={item.data as CustomerProjectCommentModelTypeExt} getData={getData} />
        </CustomerProjectActivityComponent>
    )
}

const CustomerProjectChangedTypeComponent: React.FC<CustomerProjectComponentProps> = ({ item }) => {
    const { t } = useTwinTranslation()
    return (
        <CustomerProjectActivityComponent id={item.data.EmployeeTriggerId} icon={faArrowTurnDownRight} mainColor={item?.data?.CustomerProjectStatus?.color} headerText={t('changedProjectType', 'Cambio de tipo a')} image={item?.data?.EmployeeTrigger?.profile_image} date={item.data.createdAt} secondText={item?.data?.CustomerProjectType?.name} tooltipName={'changedProjectTypeActivityTab'} nameTrigger={item?.data?.EmployeeTrigger?.fullname_short} />
    )
}
const CustomerProjectReactiveComponent: React.FC<CustomerProjectComponentProps> = ({ item }) => {
    const { t } = useTwinTranslation()
    return (
        <CustomerProjectActivityComponent id={item.data.EmployeeTriggerId} icon={faUpFromLine} mainColor={'#43BAA5'} headerText={t('reactivatedProject', 'Reactivado el proyecto')} image={item.data.EmployeeTrigger.profile_image} date={item.date} nameTrigger={item.data.EmployeeTrigger.fullname_short} tooltipName={'reactivatedProjectActivityTab'} />
    )
}
const CustomerProjectDisabledComponent: React.FC<CustomerProjectComponentProps> = ({ item }) => {
    const { t } = useTwinTranslation()
    return (
        <CustomerProjectActivityComponent id={item.data.EmployeeTriggerId} icon={faXmark} mainColor={'#BA435D'} headerText={t('disabledProject', 'Desactivado el proyecto')} image={item.data.EmployeeTrigger.profile_image} date={item.date} nameTrigger={item.data.EmployeeTrigger.fullname_short} tooltipName={'disabledProjectActivityTab'} />
    )
}

interface CustomerProjectActivityComponentProps extends CustomerProjectTabsInfoHeaderProps {
    icon: TwinIconProp
    mainColor?: string
    children?: React.ReactNode
    id?: number
    nameTrigger: string
    tooltipName: string
}

const CustomerProjectActivityComponent: React.FC<CustomerProjectActivityComponentProps> = ({ mainColor, icon, children, ...header }) => {
    return (
        <div className='flex flex-col flex-auto gap-14 relative customer_project_activity_component'>
            <div className='flex items-center'>
                <div className=' flex items-center rounded-full px-8 py-15 border bg-white mr-14 customer_project_activity_component_inner' style={{ borderColor: mainColor || '#D6D6D6' }}>
                    <TwinIcon icon={icon} className='w-16 h-16 ' style={{ color: mainColor || '#43BAA5' }}></TwinIcon>
                </div>
                <CustomerProjectTabsInfoHeader {...header} />
            </div>
            {
                children ?
                    <div className='flex flex-col flex-auto ml-48'>
                        {children}
                    </div>
                    : null
            }
        </div>
    )
}

export default CustomerProjectActivityTab