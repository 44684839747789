import { useCallback, useMemo } from 'react'
import { CustomSelectBase } from '../../../forms/CustomSelect'
import { CustomSelectSectionSearchbarDropDown, CustomSelectSectionsProps } from '../../../forms/CustomSelect/CustomSelectSections'
import { DisplayTextFuncTy } from '../../../forms/CustomSelect/types'
import { Modify } from '../../../utils/globals/types'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { CustomerPayload, ReduxCustomerTypes } from '../../../utils/reducers/customers/customer'
import { parseSearchCustomerProjectToSections } from '../SearcherCustomerProject/functions'

type CustomSelectSectionCustomersProps = Modify<CustomSelectSectionsProps, CustomSelectSectionCustomersLogicProps & {
    items?: never
}>


const CustomSelectSectionCustomers: React.FC<CustomSelectSectionCustomersProps> = ({customers, ...rest}) => {
    const { customersSectionItems, displayTextFunc } = useCustomSelectSectionCustomersLogic({ customers })
    return (
        <CustomSelectBase {...rest} items={customersSectionItems.customersSectionItems} DropDownComponent={CustomSelectSectionSearchbarDropDown} displayTextFunc={displayTextFunc} />
    )
}

interface CustomSelectSectionCustomersLogicProps {
    customers: CustomerPayload
}

const useCustomSelectSectionCustomersLogic = ({ customers }: CustomSelectSectionCustomersLogicProps) => {
    const { t } = useTwinTranslation()
    const customersTrans = t('customers', 'Clientes')
    const customersB2BTrans = t('customersB2B', 'Clientes B2B')
    const contactTrans = t('leads', 'Leads')
    const customersSectionItems = useMemo(() => {
        const types: Record<ReduxCustomerTypes, string> = {
            'customers': customersTrans,
            'customersB2B': customersB2BTrans,
            'contacts': contactTrans
        };
        return parseSearchCustomerProjectToSections(customers, types)
    }, [customers, customersTrans, customersB2BTrans, contactTrans])

    const displayTextFunc: DisplayTextFuncTy = useCallback((_items, value) => {
        return customersSectionItems.indexCustomers[value]?.text || customersSectionItems.indexCustomers[value as any]
    }, [customersSectionItems.indexCustomers])

    return { displayTextFunc, customersSectionItems }
}

export default CustomSelectSectionCustomers