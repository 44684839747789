import ModalSmallFilled from '../../../../baseComponents/ModalsLayouts/ModalSmallFilled'
import { ModalEditComponentProps } from '../../../../baseComponents/ModalsLayouts/types'
import { useFetchText } from '../../../../utils/hooks/useFetchText'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'


interface ModalReactivateCustomerProjectProps extends ModalEditComponentProps { }

const ModalReactivateCustomerProject: React.FC<ModalReactivateCustomerProjectProps> = ({ setOpened, onSubmit, allRowData }) => {
    const { t } = useTwinTranslation()
    const { handleFetchText } = useFetchText({
        onSubmit,
        url: '/api/app/customer/customerProject/updateCustomerProject',
        data: {
            id: allRowData?.id,
            active: true
        }
    })
    return (
        <ModalSmallFilled opened={true} setOpened={setOpened} onCancel={() => setOpened(null)} onAccept={handleFetchText} translations={{
            title: t('sureReactiveThisCustomerProject', '¿Seguro que quieres reactivar este proyecto?'),
            subtitle: t('onceReactivatedProjectAbleInList', 'Una vez reactivado volverá a aparecer en el listado de proyectos'),
            buttonAccept: t('reactivate', 'Reactivar')
        }} />
    )
}

export default ModalReactivateCustomerProject