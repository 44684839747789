import { faChevronDown, faCog, faPlusCircle, faSpinnerThird } from '@fortawesome/pro-light-svg-icons'
import { ButtonPrimary, ButtonRed, ButtonSecondary } from '..'
import { RadioWithLabel } from '../../../forms/RadioSelector'
import LoadingSpinner from '../../LoaderDecider/LoadingSpinner'
import TwinIcon from '../../TwinIcon'
import TwinTrans from '../../TwinTrans'
import { WhiteBox } from '../../AppLayout/WhiteBox'
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { FilterModelType } from '@teinor/erp/types/company/config/filter'
import useOnClickOutside from '../../../utils/hooks/useOnClickOut'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { twinFetchPostJSON } from '../../../utils/globals/data'
import { TwinDictionary, dictionaryComplexFromJsonArr } from '../../../utils/globals/dictionary'
import useIsLoading from '../../../utils/hooks/useIsLoading'
import { addClassName, removeClassName } from '../../../utils/globals/components'
import { InputDefault, InputWithLabel } from '../../../forms/Input'
import { ModalOpenedTy } from '../../Modal/types'

export interface FiltersConfig {
    id?: number
    name?: string
    value?: TwinDictionary
}

interface ButtonsLoadFiltersStateFullProps extends ButtonsLoadFiltersStateFullPropsLogic {
    allowCreate: boolean
    selectedFilters: FiltersConfig | null
    onChangeFilter: (selectedFilter: FiltersConfig) => void
    className?: string
}

export const ButtonsLoadFiltersStateFull: React.FC<ButtonsLoadFiltersStateFullProps> = ({ tableName, defaultValues, ...rest }) => {
    const {filters, getAllFilters} = useButtonsLoadFiltersStateFullLogic({tableName, defaultValues})
    return (
        <ButtonLoadFiltersStateLess onOpenModal={getAllFilters} filters={filters} getAllFilters={getAllFilters} tableName={tableName} defaultValues={defaultValues} {...rest} />
    )
}

interface ButtonsLoadFiltersStateFullPropsLogic {
    tableName: string
    defaultValues: TwinDictionary
}

export const useButtonsLoadFiltersStateFullLogic = ({ tableName, defaultValues }: ButtonsLoadFiltersStateFullPropsLogic) => {
    const [filters, setFilters] = useState<FilterModelType[] | null>(null)
    const { t } = useTwinTranslation()
    const translation = t('defaultFilter', 'Filtro por defecto')
    const defaultParsed = JSON.stringify(defaultValues)
    const getAllFilters = useCallback(async () => {
        let myFilters: FilterModelType[] | false = await twinFetchPostJSON('/api/app/config/filter/getAllFilters', {
            where: {
                tableName
            },
            order: [['id', 'ASC']]
        })
        if (!myFilters) {
          myFilters = []  
        }
        myFilters.unshift({ id: 0, name: translation, EmployeeId: 0, tableName, value: defaultParsed })
        setFilters([...myFilters])
    }, [tableName, translation, defaultParsed])
    

    return { filters, getAllFilters, setFilters }
}

interface ButtonLoadFiltersProps extends ButtonLoadFiltersLogicProps {
    allowCreate: boolean
    filters: FilterModelType[] | null
    defaultValues: TwinDictionary
    getAllFilters: () => Promise<void>
    className?: string
}

export const ButtonLoadFiltersStateLess: React.FC<ButtonLoadFiltersProps> = ({ filters, getAllFilters, defaultValues, allowCreate, className, ...rest }) => {
    const { changeOpened, loadFilter, setFilterToLoad, openedModal, filterToLoad, ref } = useButtonLoadFiltersLogic({ filters, ...rest })
    const renderThis: JSX.Element[] = []
    if (filters?.length) {
        for (const filter of filters) {
            renderThis.push(<RadioWithLabel label={filter.name} id={'selectedFilter' + filter.id} className='mb-15' name='selectedFilter' checked={filterToLoad === filter.id} key={filter.id} onChange={() => setFilterToLoad(filter.id || 0)} />)
        }
    } else {
        renderThis.push(<LoadingSpinner key='spinner' className='h-30 mb-15' />)
    }
    return (
        <div className={'relative ml-auto mr-15 ' + (className || '')} ref={ref}>
            <ButtonSecondary onClick={changeOpened} type='button' className='h-34'>
                <TwinIcon icon={faCog} className='load_filters_button_icon hidden' />
                <span className='load_filters_button_text'><TwinTrans transKey='loadConfiguration'>Cargar configuración</TwinTrans></span>
                <TwinIcon icon={faChevronDown} className='ml-8' />
            </ButtonSecondary>
            {openedModal ? <WhiteBox className='absolute w-310 mt-5 right-0 p-24 bg-white z-10 top-full' >
                <div>
                    <TwinTrans transKey='selectFilterToLoad'>Selecciona el filtro a cargar</TwinTrans>
                </div>
                <div className='mt-20'>
                    {renderThis}
                    {allowCreate ? <CreateFilter {...rest} getAllFilters={getAllFilters} defaultValues={defaultValues} /> : null}
                </div>
                <div className='h-1 w-full bg-gray-EE'></div>
                <div className='mt-20'>
                    <ButtonPrimary className='w-full' onClick={loadFilter}><TwinTrans transKey='loadFilter'>Cargar filtro</TwinTrans></ButtonPrimary>
                </div>
            </WhiteBox> : null}
        </div>
    )
}

interface ButtonLoadFiltersLogicProps  {
    selectedFilters: FiltersConfig | null
    onChangeFilter: (selectedFilter: FiltersConfig) => void 
    tableName: string
    filters: FilterModelType[] | null
    onOpenModal?: () => void
}

const useButtonLoadFiltersLogic = ({ selectedFilters, onChangeFilter, filters, onOpenModal }: ButtonLoadFiltersLogicProps) => {
    const [openedModal, setOpenedModal] = useState<ModalOpenedTy>(null)
    const [filterToLoad, setFilterToLoad] = useState(parseInt(String(selectedFilters?.id || 0)))
    const ref = useRef<any>(null)

    useOnClickOutside(ref, () => setOpenedModal(null))

    const changeOpened = useCallback(async () => {
        setOpenedModal((old) => {
            if (old === null) {
                onOpenModal?.()
            }
            return old ? null : true
        })
    }, [setOpenedModal, onOpenModal])

    const loadFilter = useCallback(async () => {
        const myFilters = dictionaryComplexFromJsonArr(filters || [])
        const myFilter: FilterModelType = myFilters[filterToLoad]
        if (myFilter) {
            onChangeFilter({ value: JSON.parse(myFilter.value), id: filterToLoad, name: myFilter.name })
            setOpenedModal(null)
        }
    }, [filters, filterToLoad, onChangeFilter])

    useEffect(() => {
        if (selectedFilters?.id) {
            setFilterToLoad(selectedFilters?.id)
        }
    }, [setFilterToLoad, selectedFilters])

    return { changeOpened, loadFilter, filters, setFilterToLoad, openedModal, filterToLoad, ref }
}

interface CreateFilterProps extends CreateFilterLogicProps { }

const CreateFilter: React.FC<CreateFilterProps> = ({ defaultValues, ...rest }) => {
    const { createFilter, loading, setInputRef, setNewFilterName, newFilterName } = useCreateFilterLogic({ defaultValues, ...rest })
    const { t } = useTwinTranslation()
    return (
        <div className='mb-15 flex items-center'>
            <InputDefault className='flex-auto w-1' placeholder={t('nameForCreateFilter', 'Nombre para crear filtro')} value={newFilterName} onChange={setNewFilterName} myref={setInputRef as any} readOnly={loading} />
            {loading ? <TwinIcon icon={faSpinnerThird} fontSize={20} className='ml-20 cursor-pointer text-green-43' spin={true} /> : <TwinIcon icon={faPlusCircle} fontSize={20} className='ml-20 cursor-pointer' onClick={createFilter} />}
        </div>
    )
}

interface CreateFilterLogicProps {
    tableName: string
    getAllFilters: () => void
    defaultValues: TwinDictionary
}

const useCreateFilterLogic = ({ tableName, getAllFilters,  defaultValues}: CreateFilterLogicProps) => {
    const [newFilterName, setNewFilterName] = useState('')
    const [inputRef, setInputRef] = useState<any>(null)
    const { loading, startLoading, endLoading } = useIsLoading()
    const defaultParsed = JSON.stringify(defaultValues)

    const createFilter = useCallback(async () => {
        if (inputRef && !newFilterName) {
            addClassName('input_error', inputRef)
            inputRef.focus()
        } else {
            removeClassName('input_error', inputRef)
            startLoading()
            const defaultSelected = defaultParsed
            await twinFetchPostJSON('/api/app/config/filter/createFilter', {
                tableName,
                name: newFilterName,
                value: defaultSelected
            })
            await getAllFilters()
            setNewFilterName('')
            endLoading()
        }
    }, [newFilterName, inputRef, tableName, endLoading, startLoading, getAllFilters, defaultParsed])

    return { createFilter, loading, setInputRef, setNewFilterName, newFilterName }
}

interface NameAndModifyFiltersProps extends NameAndModifyFiltersLogicProps {}

export const NameAndModifyFilters: React.FC<NameAndModifyFiltersProps> = ({ selectedFilters, ...rest }) => {
    const { opened, changeOpened, setOpened, ref} = useNameAndModifyFiltersLogic()
    return (
        <div className='flex items-center relative' ref={ref}>
            <FilterTitle selectedFilters={selectedFilters} changeOpened={changeOpened} />
            {opened ? <ModalEditFilter selectedFilters={selectedFilters} setOpened={setOpened} {...rest} /> : null}
        </div>
    )
}

interface NameAndModifyFiltersLogicProps {
    onChangeFilter: (selectedFilter: FiltersConfig) => void
    getAllFilters: () => void
    filters: FilterModelType[] | null
    selectedFilters: FiltersConfig | null
}

const useNameAndModifyFiltersLogic = () => {
    const [opened, setOpened] = useState<boolean>(false)
    const ref = useRef<any>(null)

    useOnClickOutside(ref, () => setOpened(false))

    const changeOpened = useCallback(() => {
        setOpened((old) => !old)
    }, [])

    return { opened, changeOpened, setOpened, ref }
}

interface FilterTitleProps {
    selectedFilters: FiltersConfig | null
    changeOpened: () => void
}

const FilterTitle: React.FC<FilterTitleProps> = ({ selectedFilters, changeOpened }) => {
    const { t } = useTwinTranslation()
    return (
        <div>
            <span className='regular16'>{selectedFilters?.name || t('filterByDefault', 'Filtro por defecto')}</span>{String(selectedFilters?.id || '0') !== '0' ? <TwinIcon icon={faCog} className='text-16 ml-5 cursor-pointer' onClick={changeOpened} /> : null}
        </div>
    )
}

interface ModalEditFilterProps extends ModalEditFilterLogicProps {}

const ModalEditFilter: React.FC<ModalEditFilterProps> = ({ ...rest }) => {
    const { t } = useTwinTranslation()
    const {changeField, deleteFilter, saveFilter, data} = useModalEditFilterLogic({...rest})
    return (
        <WhiteBox className='absolute w-310 mt-5 left-0 p-24 z-10 top-full'>
            <div>
                <TwinTrans transKey='modifyConfig'>Modificar configuración</TwinTrans>
            </div>
            <div className='mt-25'>
                <InputWithLabel label={t('name', 'Nombre')} value={data?.name || ''} onChange={(value) => changeField(value, 'name')} />
            </div>
            <div className='h-1 w-full bg-gray-EE'></div>
            <div className='mt-20 grid grid-cols-2 gap-15'>
                <ButtonRed onClick={deleteFilter}><TwinTrans transKey='delete'>Eliminar</TwinTrans></ButtonRed>
                <ButtonPrimary onClick={saveFilter}><TwinTrans transKey='save'>Guardar</TwinTrans></ButtonPrimary>
            </div>

        </WhiteBox>
    )
}

interface ModalEditFilterLogicProps {
    onChangeFilter: (selectedFilter: FiltersConfig) => void
    getAllFilters: () => void
    filters: FilterModelType[] | null
    selectedFilters: FiltersConfig | null
    setOpened: Dispatch<SetStateAction<boolean>>
}

const useModalEditFilterLogic = ({filters, getAllFilters, onChangeFilter, selectedFilters, setOpened}: ModalEditFilterLogicProps) => {
    const [data, setData] = useState<null | FiltersConfig>(selectedFilters)
    const changeField = useCallback((value: string, field: keyof FilterModelType) => {
        setData((old) => {
            if (!old) {
                return old
            }
            return { ...old, [field]: value }
        })
    }, [])

    const saveFilter = useCallback(async (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        if (data) {
            const updated = await twinFetchPostJSON('/api/app/config/filter/updateFilter', {
                id: data.id,
                name: data.name
            })
            if (updated) {
                onChangeFilter({ value: data.value, id: data.id, name: data.name })
                setOpened(false)
                await getAllFilters()
            }
        }
    }, [data, getAllFilters, onChangeFilter, setOpened])

    const deleteFilter = useCallback(async (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        if (data) {
            const deleted = await twinFetchPostJSON('/api/app/config/filter/deleteFilter', {
                id: data?.id
            })
            if (deleted && filters) {
                const myFilter: FilterModelType | undefined = filters?.['0']
                onChangeFilter({ value: JSON.parse(myFilter.value), id: myFilter.id, name: myFilter.name })
                setOpened(false)
                await getAllFilters()
            }
        }
    }, [data, getAllFilters, onChangeFilter, filters, setOpened])
    return {changeField, saveFilter, deleteFilter, data}
}