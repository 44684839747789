import { EmployeeExpenseModelType, EmployeeExpenseStatusKeys } from '@teinor/erp/types/company/userInner/employee/employeeExpense'
import { useParams } from 'react-router-dom'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import { DisplayStatusWithoutIcon } from '../../../../../../../../../baseComponents/Displays/DisplayStatus'
import TwinTable from '../../../../../../../../../baseComponents/TwinTable'
import BaseScreenTableInner from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner'
import { TableComponentBaseScreenInnerProps } from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner/types'
import { CustomRenderBoolean, CustomRenderDate, CustomRenderDisplayPriceWOParams, EditRow } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useEmployeeExpensesType from '../../../../../../../../../utils/hooks/employeeExpenses/useEmployeeExpensesType'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import ModalCreateEmployeeExpenses from './ModalCreateEmployeeExpenses'
import ModalEditEmployeeExpenses from './ModalEditEmployeeExpenses'



type EmployeeExpensesProps = ComponentWithPermissions & {}

const EmployeeExpenses: React.FC<EmployeeExpensesProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()
    const pageData = {
        title: t('expenses', 'Gastos'),
        newText: t('addEmployeeExpense', 'Añadir gasto'),
    }
    return (
        <BaseScreenTableInner TableComponent={TableEmployeeExpenses} pageData={pageData} EditModalComponent={ModalEditEmployeeExpenses} userPermissions={userPermissions} extraWhereParams={{}} CreateModalComponent={ModalCreateEmployeeExpenses}/>
    )
}
interface TableEmployeeExpensesProps extends TableComponentBaseScreenInnerProps { }

const TableEmployeeExpenses: React.FC<TableEmployeeExpensesProps> = ({ setTableInstance, setOpened }) => {
    const { t } = useTwinTranslation()
    const params = useParams<{ id: string }>()
    const id = params.id
    const { expenseStatus } = useEmployeeExpensesType()

    const columns: ColumnTableSchema<EmployeeExpenseModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            width: 50
        },
        {
            id: 'dateStart',
            dataKey: 'dateStart',
            label: t('dateStart', 'Desde'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
            sortable: true
        },
        {
            id: 'dateEnd',
            dataKey: 'dateEnd',
            label: t('dateEnd', 'Hasta'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />
        },
        {
            id: 'km',
            dataKey: 'km',
            label: t('km', 'Km'),
        },
        {
            id: 'location',
            dataKey: 'location',
            label: t('location', 'Ubicación'),
        },
        {
            id: 'abroad',
            dataKey: 'abroad',
            label: t('abroad', 'Extranjero'),
            width: 90,
            customRender: (parameterValue) => <CustomRenderBoolean value={String(parameterValue) === 'true'} width={67} />
        },
        {
            id: 'overnight_stay',
            dataKey: 'overnight_stay',
            label: t('overnightStay', 'Pernoctación'),
            width: 100,
            customRender: (parameterValue) => <CustomRenderBoolean value={String(parameterValue) === 'true'} width={100} />,
        },
        {
            id: 'total_amount',
            dataKey: 'total_amount',
            label: t('totalAmount', 'Importe total'),
            customRender: (parameterValue, allRowData) => <CustomRenderDisplayPriceWOParams value={parameterValue} CurrencyId={allRowData?.CurrencyId} />
        },
        {
            id: 'status',
            dataKey: 'status',
            label: t('status', 'Estado'),
            customRender: (parameterValue) => <DisplayStatusWithoutIcon text={expenseStatus[parseInt(parameterValue) as EmployeeExpenseStatusKeys].name} colorText={expenseStatus[parseInt(parameterValue) as EmployeeExpenseStatusKeys].colorText} />
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        }
    ]

    return (
        <TwinTable columns={columns} getDataFrom='/api/app/employeeExpense/getAllEmployeeExpenses' extraWhereParams={{ 'EmployeeId': id }} name='employeeExpenses' setTableInstance={setTableInstance} onRowClick={(_id, rowData) => setOpened({
            type: 'edit',
            allRowData: rowData
        })} />
    )
}


export default EmployeeExpenses