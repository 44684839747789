import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { customerTypes } from '@teinor/erp/types/company/customer/customerTypes'
import { useLocation } from 'react-router'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import { TwinTableLink } from '../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { EditRow, CustomRenderString } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import ModalCreateContact from './ModalCreateContact'
import { EmployeesPayload } from '../../../../../../utils/reducers/company/employees'
import { connect, ConnectedProps } from 'react-redux'
import { AllReduxPayloads } from '../../../../../../utils/reducers'
import withLoading from '../../../../../../utils/hoc/withLoading'

interface ContactsProps extends ComponentWithPermissions { }

const Contacts: React.FC<ContactsProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('leads', 'Leads'),
        newText: t('newLead', 'Nuevo lead'),
    }

    return (
        <BaseScreenTable TableComponent={TableContactsConnect} CreateModalComponent={ModalCreateContact} pageData={pageData} userPermissions={userPermissions} />
    )
}

type TableContactsProps = TableComponentProps & ReduxTableContacts  & {}

const TableContacts: React.FC<TableContactsProps> = ({ userPermissions, setTableInstance, setOpened, employees }) => {
    const { t } = useTwinTranslation()
    const location = useLocation().pathname
    const columns: ColumnTableSchema<CustomerModelType> = [
        {
            id: 'code',
            dataKey: 'code',
            label: t('code', 'Código'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('customerCode', 'Código de cliente') }
            },
            searchKey: 'Customer.code'
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },
            searchKey: 'Customer.name'
        },
        {
            id: 'surname',
            dataKey: 'surname',
            label: t('surname', 'Apellidos'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('surname', 'Apellidos') }
            }
        },
        {
            id: 'fiscal_name',
            dataKey: 'fiscal_name',
            label: t('fiscalName', 'Nombre fiscal'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('fiscalName', 'Nombre fiscal') }
            }
        },
        {
            id: 'email',
            dataKey: 'email',
            label: t('email', 'Email'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },
            searchKey: 'Customer.email'
        },
        {
            id: 'phone',
            dataKey: 'phone',
            label: t('phone', 'Teléfono'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('phone', 'Teléfono') }
            },
            searchKey: 'Customer.phone'
        },
        {
            id: 'mobile',
            dataKey: 'mobile',
            label: t('mobile', 'Móvil'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('mobile', 'Móvil') }
            },
            searchKey: 'Customer.mobile'
        },
        {
            id: 'CustomerOriginId',
            dataKey: 'CustomerOriginId',
            label: t('origin', 'Origen'),
            customRender: (_parameterValue, allRowData) => <CustomRenderString value={allRowData?.CustomerOrigin?.name} />,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('origin', 'Origen') }
            },
            searchKey: 'CustomerOrigin.name',
            hideColumnByDefault: true
        },
        {
            id: 'ResponsibleEmployeeId',
            dataKey: 'ResponsibleEmployeeId',
            label: t('ourManager', 'Nuestro gestor'),
            customRender: (_parameterValue, allRowData) => <CustomRenderString value={allRowData?.ResponsibleEmployee?.fullname_short} />,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: employees || {}, label: t('ourManager', 'Nuestro gestor'), fieldName:'fullname_short' }
            },
            hideColumnByDefault: true
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        },
    ]
    return (
        <TwinTableLink createRowDataLink={[location, ['$id', 'main']]} name='contacts' columns={columns} getDataFrom='/api/app/customer/getAllCustomers' extraWhereParams={{ 'customer_type': customerTypes['contact'] }} setTableInstance={setTableInstance} />
    )
}
const tableContactsDispatch = {
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
}

type ReduxTableContacts = ConnectedProps<typeof tableContactsConnect>
const mapTableContactsConnector = (state: AllReduxPayloads) => ({ employees: state.employees })
const tableContactsConnect = connect(mapTableContactsConnector, tableContactsDispatch)
const tableContactsLoading = withLoading(TableContacts, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }])
const TableContactsConnect = tableContactsConnect(tableContactsLoading)
export default Contacts