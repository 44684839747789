import { faPlus } from '@fortawesome/pro-light-svg-icons'
import TwinIcon, { TwinIconProp } from '../../TwinIcon'
import PermissionChecker from '../../PermissionChecker'
import { UserPermissionsSingle } from '../../CreateRoutering/types'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'

interface ButtonAddChecklistSectionBaseProps {
    buttonText: string
    icon: TwinIconProp
    addSection: () => void
    className?: string
}

export const ButtonAddChecklistSectionBase: React.FC<ButtonAddChecklistSectionBaseProps> = ({ buttonText, icon, addSection, className }) => {
    return (
        <div className={'cursor-pointer text-center my-10 bg-gray-EF py-8 text-gray-51 ' + (className || '')} onClick={addSection}>
            <TwinIcon className='mr-10' icon={icon} />
            {buttonText}
        </div>
    )
}
interface ButtonAddChecklistSectionProps {
    addSection: () => void
    userPermission: UserPermissionsSingle | false
}

const ButtonAddChecklistSection: React.FC<ButtonAddChecklistSectionProps> = ({ addSection, userPermission }) => {
    const {t} = useTwinTranslation()
    return (
        <PermissionChecker userPermissions={userPermission} permission='create'>
            <ButtonAddChecklistSectionBase className='ml-16' icon={faPlus} buttonText={t('addSection', 'Añadir sección')} addSection={addSection} />
        </PermissionChecker>
    )
}

export default ButtonAddChecklistSection