import { TaskCFieldValueModelType } from '@teinor/erp/types/company/task/taskCFieldValue'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import { ComponentWithPermissions } from '../../../../../../../baseComponents/CreateRoutering/types'
import { permissionCheck } from '../../../../../../../baseComponents/PermissionChecker/function'
import TwinTable from '../../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { CustomRenderColor, DeleteRow, EditRowNotLink } from '../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import ModalCETaskCustomFieldValue from './ModalCETaskCustomFieldValue'
import ModalDeleteTaskCustomFieldValue from './ModalDeleteTaskCustomFieldValue'
import TaskCustomFieldValueSortButton from './TaskCustomFieldValueSortButton'

interface TaskCustomFieldValuesProps extends ComponentWithPermissions { }

const TaskCustomFieldValues: React.FC<TaskCustomFieldValuesProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()
    const pageData = {
        title: t('customFieldValues', 'Valores de campos personalizados'),
        newText: t('newValue', 'Nuevo valor'),
    }

    return (
        <BaseScreenTable TableComponent={TableTaskCustomFieldValues} CreateModalComponent={ModalCETaskCustomFieldValue} EditModalComponent={ModalCETaskCustomFieldValue} DeleteModalComponent={ModalDeleteTaskCustomFieldValue} pageData={pageData} userPermissions={userPermissions} />
    )
}

interface TableTaskCustomFieldValuesProps extends TableComponentProps { }

const TableTaskCustomFieldValues: React.FC<TableTaskCustomFieldValuesProps> = ({ tableInstance, setTableInstance, userPermissions, setOpened }) => {
    const { t } = useTwinTranslation()
    const { TaskCFieldId } = useParams()
    const extraWhereParams = { 'TaskCFieldId': TaskCFieldId ? parseInt(TaskCFieldId) : null }
    const columns: ColumnTableSchema<TaskCFieldValueModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            sortable: true,
            width: 75
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            }
        },
        {
            id: 'color',
            dataKey: 'color',
            label: t('color', 'Color'),
            customRender: (parameterValue) =><CustomRenderColor value={parameterValue} />,
        },
        {
            id: 'order',
            dataKey: 'order',
            label: t('order', 'Orden'),
            sortable: true,
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRowNotLink key={'edit' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'edit', allRowData })} />
        },
        {
            id: 'delete',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('delete', 'Eliminar'),
            customRender: (parameterValue, allRowData) => <DeleteRow key={'delete' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'delete', allRowData })} />,
            havePermission: permissionCheck(userPermissions, 'delete')
        }
    ]
    const children = useMemo(() => <TaskCustomFieldValueSortButton tableInstance={tableInstance} userPermissions={userPermissions} />, [tableInstance, userPermissions])
    return (
        <TwinTable columns={columns} name='taskCustomFieldValues' extraWhereParams={extraWhereParams} getDataFrom='/api/app/task/customField/value/getAllTaskCFieldValues' setTableInstance={setTableInstance} defaultOrder={{ defaultOrderField: 'order', order: 'asc' }} hideLoadFiltersButton={true} hideChangeColumnsButton={true}>
            {children}
        </TwinTable>
    )

}

export default TaskCustomFieldValues