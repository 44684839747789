import { CodeCustomerToShowTyInversed } from '@teinor/erp/types/company/config/parameter';
import { CustomSelectSectionItems, ItemCustomSelectDropDownRowSection } from '../../../forms/CustomSelect/CustomSelectSections/types';
import { TwinDictionary } from '../../../utils/globals/dictionary';
import { CustomerPayload, ReduxCustomerTypes } from '../../../utils/reducers/customers/customer';
import { getConfigParam } from '../../../utils/reducers/getters';

export const parseSearchCustomerProjectToSections = (customers: CustomerPayload, typeCustomers: Record<ReduxCustomerTypes, string>) => {
    const sections: CustomSelectSectionItems = []
    const customersSectionItems: ItemCustomSelectDropDownRowSection[] = []
    const indexCustomers: TwinDictionary = {}
    const codeCustomerToShow = getConfigParam('codeCustomerToShow')
    let fieldId: 'id' | 'code' = 'id'
    if (parseInt(String(codeCustomerToShow)) === parseInt(String(CodeCustomerToShowTyInversed['codeCustomer']))) {
        fieldId = 'code'
    }
    if(customers){
        for (const type in typeCustomers) {
            const parsedType = type as ReduxCustomerTypes
            const nameSection = typeCustomers[parsedType]
            if (Object.keys(customers[parsedType]).length){
                const myChilds = customers[parsedType]
                customersSectionItems.push({ value: '', text: nameSection, isSection: true })
                for (const value in myChilds) {
                    const customer = myChilds[value]
                    const text = (customer?.[fieldId] ? customer[fieldId] + ' -' : '') + ' ' + customer.name + ' ' + (customer.surname || '')
                    const hiddenText = customer?.fiscal_name
                    customersSectionItems.push({ ...customer, value, text, hiddenText  })
                    indexCustomers[value] = { ...customer, value, text, hiddenText }
                }
                sections.push({ name: nameSection, childs: myChilds })
            }
        }
    }
    return { customersSectionItems, indexCustomers}
}