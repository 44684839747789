import { ColumnConfiguration } from '../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types';

export const parseTaskColumnsForGetAll = (columns: ColumnConfiguration[]) => {
    const result: any[] = []
    const copyCols = JSON.parse(JSON.stringify(columns))
    for (const element of copyCols) {
        delete element.label
        delete element.minWidth
        result.push(element)
    }
    return result
}