import { CustomerModelType } from '@teinor/erp/types/company/customer'
import ModalCreateEdit from '../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { useTypeIdNumber } from '../../../../../../../utils/hooks/useTypeIdNumber'
import { getActiveLangs } from '../../../../../../../utils/reducers/getters'
import { useCallback, useEffect, useState } from 'react'
import { dictionaryComplexFromJsonArr, TwinDictionary } from '../../../../../../../utils/globals/dictionary'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'

interface ModalCreateContactProps extends ModalComponentProps { }

const ModalCreateContact: React.FC<ModalCreateContactProps> = ({ ...rest }) => {
    const { t } = useTwinTranslation()
    const { typesIdNumber } = useTypeIdNumber()
    const { dictCustomerOrigins } = useMocalCreateContactLogic()
    const fields: FormRender<CustomerModelType> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'surname',
                    label: t('surname', 'Apellido'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'fiscal_name',
                    label: t('fiscalName', 'Nombre fiscal'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'type_id_number',
                    label: t('typeIdNumber', 'Tipo de documento identificación'),
                    component: 'CustomSelect',
                    items: typesIdNumber,
                    value: 0
                },
                {
                    name: 'id_number',
                    label: t('id_number', 'DNI'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'code',
                    label: t('customerCode', 'Código de Cliente'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
            ]
        },
        {
            cols: 2,
            title: t('contactInfo', 'Información de contacto'),
            elements: [
                {
                    name: 'phone',
                    label: t('phone', 'Teléfono'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'mobile',
                    label: t('mobile', 'Móvil'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'email',
                    label: t('email', 'Email'),
                    component: 'InputWithLabelMargin',
                    type: 'email'
                },
                {
                    name: 'web',
                    label: t('web', 'Página web'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'LangId',
                    label: t('language', 'Idioma'),
                    component: 'CustomSelect',
                    items: getActiveLangs(),
                },
                {
                    name: 'CustomerOriginId',
                    label: t('origin', 'Origen'),
                    component: 'CustomSelect',
                    items: dictCustomerOrigins || {},
                },
                {
                    name: 'customer_type',
                    component: 'InputHidden',
                    value: 2
                }
            ]
        }
    ]
    return (
        <ModalCreateEdit fields={fields} url={'/api/app/customer/createCustomer'} translations={{
            title: t('createLead', 'Crear Lead'),
            button: t('create', 'Crear')
        }} haveButtonPermissions={true} {...rest} />
    )
}

const useMocalCreateContactLogic = () => {
    const [dictCustomerOrigins, setDictCustomerOrigins] = useState<TwinDictionary | null>(null)

    const getDictCustomerOrigins = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/customerOrigin/getAllCustomerOrigins', {})
        if (result) {
            setDictCustomerOrigins(dictionaryComplexFromJsonArr(result))
        }
    }, [setDictCustomerOrigins])

    useEffect(() => {
        getDictCustomerOrigins()
    }, [getDictCustomerOrigins])

    return { dictCustomerOrigins }
}

export default ModalCreateContact