import { useCallback } from 'react'
import { LittleModalCreateEdit } from '../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import { permissionCheck } from '../../../../../../../baseComponents/PermissionChecker/function'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import { valueOrDefaultValue } from '../../../../../../../utils/globals/valueOrDefaultValue'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { TaskTypeModelType } from '@teinor/erp/types/company/task/taskType'

interface ModalCETaskTypeProps extends ModalEditComponentProps { }

const ModalCETaskType: React.FC<ModalCETaskTypeProps> = ({ userPermissions, allRowData, ...rest }) => {
    const { t } = useTwinTranslation()
    const { getDefaultRandomColorTaskType } = useModalCETaskTypeLogic()
    const fields: FormRender<TaskTypeModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'defaultMins',
                    label: t('estimatedTime', 'Tiempo estimado'),
                    component: 'InputHour',
                    value: String(allRowData?.defaultMins || 60),
                },
                {
                    name: 'color',
                    label: t('color', 'Color'),
                    component: 'InputColorWithMargin',
                    required: true,
                    defaultValue: allRowData?.color || getDefaultRandomColorTaskType()
                },
                {
                    name: 'icon',
                    label: t('icon', 'Icono'),
                    component: 'InputIconWithMargin',
                    defaultValue: allRowData?.icon
                },
                {
                    name: 'id',
                    component: 'InputHidden'
                },
            ]
        },
    ]
    if (allRowData && userPermissions) {
        const parsedFields = valueOrDefaultValue(fields, userPermissions, allRowData)
        return (<LittleModalCreateEdit fields={parsedFields} url={'/api/app/task/taskType/updateTaskType'} translations={{ title: t('editTaskType', 'Editar tipo de tarea'), button: t('save', 'Guardar') }} haveButtonPermissions={permissionCheck(userPermissions, 'update')} {...rest}></LittleModalCreateEdit>)
    }
    return (<LittleModalCreateEdit fields={fields} url={'/api/app/task/taskType/createTaskType'} translations={{
        title: t('createTaskType', 'Crear tipo de tarea'),
        button: t('create', 'Crear')
    }} haveButtonPermissions={true} {...rest} />)
}


const useModalCETaskTypeLogic = () => {
    const getDefaultRandomColorTaskType = useCallback(() => {
        const colors = [
            '#6299E3',
            '#90BDDA',
            '#F8A7C1',
            '#AECB6C',
            '#F88754',
            '#F5AE56',
            '#D891EE',
            '#21B37D'
        ]
        const index = Math.floor(Math.random() * colors.length)
        return colors[index]
    }, [])

    return { getDefaultRandomColorTaskType }
}

export default ModalCETaskType