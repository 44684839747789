import { faCheckCircle, faLock } from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle as faCheckCircleSolid } from '@fortawesome/pro-solid-svg-icons'
import TwinIcon from '../../baseComponents/TwinIcon';
import { Modify } from '../../utils/globals/types';
import { useCallback } from 'react';
import './checkbox.sass'

export type CheckboxMarginProps = CheckboxProps & {}

export const CheckboxMargin: React.FC<CheckboxMarginProps> = ({ className, ...rest }) => {
    return (
        <Checkbox className={'checkbox_margin ' + (className || '')} {...rest} />
    )
}

export type CheckboxProps = Modify<InputCheckboxProps, {
    label: string
}>

const Checkbox: React.FC<CheckboxProps> = ({ id, label, name, className, onChange, ...rest }) => {
    if (rest.readOnly) {
        return <CheckboxReadOnly name={name} id={id} label={label} className={className} />
    }
    return (
        <div className={'inline-flex items-center input_field_checkbox ' + (className || '')} >
            <label className='inline-flex items-center cursor-pointer'>
                <InputCheckbox name={name}  {...{ onChange, ...rest, id }} />
                <span>{label}</span>
            </label>
        </div>
    );
}

const CheckboxReadOnly: React.FC<CheckboxProps> = ({ id, label, name, className, checked }) => {
    return (
        <div id={id} className={'inline-flex items-center input_field_checkbox ' + (className || '')} >
            <label className='inline-flex items-center cursor-pointer text-gray-97'>
                <TwinIcon icon={faLock} fontSize={16} className='mr-8 w-18 h-18'/>
                <span>{label}</span>
                {name ? <input type='checkbox' checked={checked} className='hidden' name={name} /> : null}
            </label>
        </div>
    );
}

type InputCheckboxProps = Modify<React.HTMLProps<HTMLInputElement>, {
    onChange?(value: boolean): void
}>

const InputCheckbox: React.FC<InputCheckboxProps> = ({ name, className, onChange, readOnly, ...rest }) => {
    let defaultStyle = 'checked:bg-green-43 text-green-43'
    return (
        <input type='checkbox' name={name} className={(className || '') + ' inputcheckbox_checkbox cursor-pointer appearance-none border rounded border-gray-DD hover:bg-transparent  focus:ring-0 focus:ring-transparent focus:ring-offset-transparent ' + defaultStyle} onChange={(e) => !readOnly ? onChange?.(e.currentTarget.checked) : false} {...rest} readOnly={readOnly} />
    )
}

interface CheckboxRoundedProps extends CheckboxRoundedLogicProps {
    value: boolean
    className?: string
    readOnly?: boolean
}
export const CheckboxRounded: React.FC<CheckboxRoundedProps> = ({ value, className, readOnly, ...rest }) => {
    const { handleClick } = useCheckboxRoundedLogic({ ...rest })
    return (
        <div className={'flex items-center cursor-pointer ' + (className || '')} onClick={readOnly ? undefined : (e) => handleClick(e, value)}>
            <div className='text-center' >
                <TwinIcon icon={value ? faCheckCircleSolid : faCheckCircle} className={value ? 'text-green-43' : 'text-gray-9D'} size='lg' />
            </div>
        </div>
    )
}


interface CheckboxRoundedLogicProps {
    onChange?: (val: boolean) => void
}
const useCheckboxRoundedLogic = ({ onChange }: CheckboxRoundedLogicProps) => {

    const handleClick = useCallback((e: React.MouseEvent, value: boolean) => {
        e.preventDefault()
        e.stopPropagation()
        onChange?.(!value)
    }, [onChange])

    return { handleClick }
}

export default Checkbox;

