
import { TaskTypeModelType } from '@teinor/erp/types/company/task/taskType'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import TwinTable from '../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { CustomRenderColor, CustomRenderDisplayHoursByMins, CustomRenderIcon, EditRowNotLink } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import ModalCETaskType from './ModalCETaskType'
import { dictIcon } from '../../../../../../utils/icons'

interface TaskTypesProps extends ComponentWithPermissions { }

const TaskTypes: React.FC<TaskTypesProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('taskTypes', 'Tipos de tarea'),
        newText: t('newTaskType', 'Nuevo tipo de tarea'),
    }

    return (
        <BaseScreenTable TableComponent={TableTaskTypes} CreateModalComponent={ModalCETaskType} EditModalComponent={ModalCETaskType} pageData={pageData} userPermissions={userPermissions} />
    )
}

type TableTaskTypesProps = TableComponentProps & {}

const TableTaskTypes: React.FC<TableTaskTypesProps> = ({ setTableInstance, setOpened }) => {
    const { t } = useTwinTranslation()
    const columns: ColumnTableSchema<TaskTypeModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            sortable: true,
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            }
        },
        {
            id: 'color',
            dataKey: 'color',
            label: t('color', 'Color'),
            customRender: (parameterValue) => <CustomRenderColor value={parameterValue} />
        },
        {
            id: 'icon',
            dataKey: 'icon',
            label: t('icon', 'Icono'),
            customRender: (parameterValue) => <CustomRenderIcon icon={dictIcon[parameterValue]} />
        },
        {
            id: 'defaultMins',
            dataKey: 'defaultMins',
            label: t('estimatedTime', 'Tiempo estimado'),
            customRender: (parameterValue) => <CustomRenderDisplayHoursByMins value={parameterValue}/>
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRowNotLink key={'edit' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'edit', allRowData })} />
        },
    ]
    return (
        <TwinTable name='TaskTypes' columns={columns} getDataFrom='/api/app/task/taskType/getAllTaskTypes' onRowClick={(_id, rowData) => setOpened({
            type: 'edit',
            allRowData: rowData
        })} setTableInstance={setTableInstance} />
    )
}

export default TaskTypes
