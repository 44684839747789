import { ComponentWithPermissions } from '../../../../../baseComponents/CreateRoutering/types'
import { permissionCheck } from '../../../../../baseComponents/PermissionChecker/function'
import TwinTable from '../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { EditRow } from '../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import { TChecklistModelType } from '@teinor/erp/types/company/config/customerProject/tChecklist'
import ModalCreateTChecklist from './ModalCreateTChecklist'
import ModalEditTChecklist from './ModalEditTChecklist'

interface ConfigTemplateChecklistsProps extends ComponentWithPermissions { }


const ConfigTemplateChecklists: React.FC<ConfigTemplateChecklistsProps> = ({ userPermissions }) => {
    const {t} = useTwinTranslation()
    const pageData = {
        title: t('templateChecklist', 'Plantillas de listas'),
        newText: t('newTemplateChecklist', 'Nueva plantilla de lista'),
    }
    return (
        <BaseScreenTable TableComponent={TableTemplateChecklists} CreateModalComponent={ModalCreateTChecklist} EditModalComponent={ModalEditTChecklist} pageData={pageData} userPermissions={userPermissions} />
    )
}

interface TableTemplateChecklistsProps extends TableComponentProps { }

const TableTemplateChecklists: React.FC<TableTemplateChecklistsProps> = ({ userPermissions, setTableInstance, setOpened, tableInstance }) => {
    const { t } = useTwinTranslation()

    const columns: ColumnTableSchema<TChecklistModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            sortable: true,
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },
            searchKey: 'TChecklist.name'
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'edit', allRowData })} />,
            havePermission: permissionCheck(userPermissions, 'update')
        },
    ]
    return (
        <TwinTable name='tChecklists' columns={columns} getDataFrom='/api/app/config/templateChecklist/getAllTChecklists' setTableInstance={setTableInstance} onRowClick={(_id, rowData) => setOpened({ type: 'edit', allRowData: rowData })} />
    )
}

export default ConfigTemplateChecklists