import { CustomerProjectStatusModelType } from '@teinor/erp/types/company/customer/customerProjectStatus'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MediumModalCreateEdit } from '../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../baseComponents/ModalsLayouts/types'
import { permissionCheck } from '../../../../../../../../baseComponents/PermissionChecker/function'
import { RowData } from '../../../../../../../../baseComponents/TwinTable/types'
import { FormRender } from '../../../../../../../../forms/FormRenderer/types'
import { twinFetchPostJSON } from '../../../../../../../../utils/globals/data'
import { valueOrDefaultValue } from '../../../../../../../../utils/globals/valueOrDefaultValue'
import useTwinTranslation from '../../../../../../../../utils/hooks/useTwinTranslation'
import { customerProjectTypeColors } from '../../../../../../../../utils/globals/colors'
import { arrayFromArrayDictionaryAttribute } from '../../../../../../../../utils/globals/array'
import { GroupPayload } from '../../../../../../../../utils/reducers/groups/groups'
import { EmployeesPayload } from '../../../../../../../../utils/reducers/company/employees'
import { connect, ConnectedProps } from 'react-redux'
import { AllReduxPayloads } from '../../../../../../../../utils/reducers'
import withLoading from '../../../../../../../../utils/hoc/withLoading'

type ModalCECustomProjectStatusProps = ReduxModalCECustomerProjectStatus & ModalEditComponentProps & { }

interface CustomerProjectStatusModelTypeExtended extends CustomerProjectStatusModelType{
    limit_date: string
}

const ModalCECustomProjectStatus: React.FC<ModalCECustomProjectStatusProps> = ({ userPermissions, allRowData, groups, employees, ...rest }) => {
    const { t } = useTwinTranslation()
    const params = useParams<{ CustomerProjectTypeId?: string }>()
    const { colorRandom, colors, selectedEmployees, selectedGroups } = useModalCECustomProjectStatusLogic({ allRowData })

    const fields: FormRender<CustomerProjectStatusModelTypeExtended> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true,
                },
                {
                    name: 'color',
                    label: t('color', 'Color'),
                    component: 'InputColorWithMargin',
                    colors: colors,
                    defaultValue: allRowData?.color || colorRandom,
                },
                {
                    name: 'listing',
                    label: t('listing', 'Listar'),
                    component: 'CheckboxMargin',
                    defaultChecked: allRowData?.listing ?? true,
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: allRowData?.id
                },
                {
                    name: 'CustomerProjectTypeId',
                    component: 'InputHidden',
                    value: params?.CustomerProjectTypeId
                },
            ]
        },
        {
            cols: 1,
            title: t('employeesResponsible', 'Empleados responsables'),
            elements: [
                {
                    name: 'Employees',
                    component: 'MultipleCheckboxSelector',
                    subtitles: { subtitleOptions: t('notSelected', 'Sin seleccionar'), subtitleSelectedOptions: t('selected', 'Seleccionados') },
                    items: employees || {},
                    defaultValue: selectedEmployees,
                    keyName: 'fullname_short'
                }
            ]
        },
        {
            cols: 1,
            title: t('groupsResponsible', 'Grupos responsables'),
            elements: [
                {
                    name: 'Groups',
                    component: 'MultipleCheckboxSelector',
                    subtitles: { subtitleOptions: t('notSelected', 'Sin seleccionar'), subtitleSelectedOptions: t('selected', 'Seleccionados') },
                    items: groups || {},
                    defaultValue: selectedGroups
                }
            ]
        }
    ]
    if (allRowData && userPermissions) {
        const parsedFields = valueOrDefaultValue(fields, userPermissions, allRowData)
        return (
            <MediumModalCreateEdit fields={parsedFields} url={'/api/app/customer/customerProject/customerProjectStatus/updateCustomerProjectStatus'} translations={{ title: t('editCustomerProjectStatus', 'Editar estado del proyecto'), button: t('save', 'Guardar') }} haveButtonPermissions={permissionCheck(userPermissions, 'update')} {...rest} />
        )
    }
    return (
        <MediumModalCreateEdit fields={colorRandom ? fields : []} url={'/api/app/customer/customerProject/customerProjectStatus/createCustomerProjectStatus'} translations={{
            title: t('createCustomerProjectStatus', 'Crear estado para el proyecto'),
            button: t('create', 'Crear')
        }} haveButtonPermissions={true} {...rest} />
    )
}
interface ModalCECustomProjectStatusLogicProps {
    allRowData?: RowData
}
const useModalCECustomProjectStatusLogic = ({ allRowData }: ModalCECustomProjectStatusLogicProps) => {
    const [colorRandom, setColorRandom] = useState<null | string>(null)
    const params = useParams<{ CustomerProjectTypeId?: string }>()
    const colors = customerProjectTypeColors
    const selectedEmployees = arrayFromArrayDictionaryAttribute(allRowData?.Employees || [])
    const selectedGroups = arrayFromArrayDictionaryAttribute(allRowData?.Groups || [])

    const getRandomColor = useCallback(async () => {
        let copyColors = JSON.parse(JSON.stringify(colors))
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/customerProjectStatus/getAllCustomerProjectStatus', {
            where: { CustomerProjectTypeId: params.CustomerProjectTypeId }
        })
        if (result?.length) {
            for (const element of result) {
                const parsedColor = element.color.toUpperCase()
                const alreadySelectedColor = copyColors.indexOf(parsedColor)
                if (alreadySelectedColor !== -1) {
                    copyColors.splice(alreadySelectedColor, 1)
                }
            }
        }
        if (!copyColors.length) {
            copyColors = colors
        }
        const index = Math.floor(Math.random() * copyColors.length)
        setColorRandom(copyColors[index])
    }, [params.CustomerProjectTypeId, colors])

    useEffect(() => {
        if (!allRowData && !colorRandom) {
            getRandomColor()
        }
    }, [allRowData, colorRandom, getRandomColor])

    return { colorRandom, colors, selectedEmployees, selectedGroups }
}

const modalCECustomerProjectStatusDispatch = {
    setGroups: (payload: GroupPayload) => ({ type: 'CHANGE_GROUP', payload }),
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
}

type ReduxModalCECustomerProjectStatus = ConnectedProps<typeof modalCECustomerProjectStatusConnect>
const mapModalCECustomerProjectStatusConnector = (state: AllReduxPayloads) => ({ groups: state.groups, employees: state.employees })
const modalCECustomerProjectStatusConnect = connect(mapModalCECustomerProjectStatusConnector, modalCECustomerProjectStatusDispatch)
const modalCECustomerProjectStatusConnectLoading = withLoading(ModalCECustomProjectStatus, [{ fetchUrl: '/api/app/group/getAllGroupsListing', propName: 'groups', setFunctionName: 'setGroups' }, { fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }])
const ModalCECustomProjectStatusConnect = modalCECustomerProjectStatusConnect(modalCECustomerProjectStatusConnectLoading)

export default ModalCECustomProjectStatusConnect