import { useState, useCallback, useRef, MutableRefObject } from 'react'
import { ButtonDisabledOrLoader } from '../../../../baseComponents/Button'
import FilesClipInput from '../../../../forms/FilesInput/FilesClipInput'
import { ImageInputFile } from '../../../../forms/ImageInput/types'
import { InputHidden } from '../../../../forms/Input'
import TextArea from '../../../../forms/Input/TextArea'
import TwinForm from '../../../../forms/TwinForm'
import { OnSubmit } from '../../../../forms/TwinForm/types'
import useIsLoading from '../../../../utils/hooks/useIsLoading'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { CustomerProjectTabsInfoHeader, CustomerProjectTabsCommentBox } from '../CustomerProjectComponents'
import { CustomerProjectModelTypeExtended } from '../types'

interface CustomerProjectCommentTabProps {
    customerProjectData: CustomerProjectModelTypeExtended
    getCustomerProject: () => Promise<void>
}

const CustomerProjectCommentTab: React.FC<CustomerProjectCommentTabProps> = ({ customerProjectData, getCustomerProject }) => {
    const renderThis: JSX.Element[] = []
    const customerProjectComments = customerProjectData?.CustomerProjectComments || []
    const {ref} = useCustomerProjectCommentTabLogic()

    customerProjectComments.sort((a, b) => {
        return new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1
    })

    for (const element of customerProjectComments) {
        renderThis.push(
            <div className='flex flex-col gap-10 justify-center' key={element.id}>
                <CustomerProjectTabsInfoHeader date={element.createdAt} headerText={element.Employee?.fullname_short} id={element.id} image={element.Employee?.profile_image} nameTrigger={element.Employee?.fullname_short} tooltipName='customerProjectComment' />
                <CustomerProjectTabsCommentBox data={element} getData={getCustomerProject} />
            </div>
        )
    }
    return (
        <div className='flex flex-col mt-25 h-screen flex-auto customer_project_activity_tab'>
            <div ref={ref} className='flex flex-col flex-auto overflow-auto h-1 gap-20 relative customer_project_activity_tab_inner'>
                {renderThis}
            </div>
            <CustomerProjectCommentAddComponent customerProjectData={customerProjectData} getCustomerProject={getCustomerProject} commentBoxRef={ref} />
        </div>
    )
}

const useCustomerProjectCommentTabLogic = () => {
    const ref = useRef<any>(null)
    return {ref}
}

interface CustomProjectCommentTextAreaProps extends CustomProjectCommentTextAreaLogicProps {
    customerProjectData: CustomerProjectModelTypeExtended
}

const CustomerProjectCommentAddComponent: React.FC<CustomProjectCommentTextAreaProps> = ({ customerProjectData, getCustomerProject, commentBoxRef }) => {
    const { t } = useTwinTranslation()
    const { startLoading, endLoading, loading } = useIsLoading()
    const { handleSubmit, message, onChangeMessage, files, setFiles } = useCustomProjectCommentTextAreaLogic({ endLoading, getCustomerProject, commentBoxRef })
    const buttonIsDisabled = message || files.length ? false : true
    return (
        <TwinForm className='mt-auto pt-20' action='/api/app/customer/customerProject/customerProjectComment/createCustomerProjectComment' imagepath={['customer', String(customerProjectData.CustomerId), 'customerProjects', String(customerProjectData.id)]} beforeSubmitHandler={startLoading} onError={endLoading} onSubmit={handleSubmit} >
            <div className=' flex flex-auto items-center border border-gray-D6 px-8 py-6 rounded-lg customer_project_create_comment'>
                <div className='flex flex-auto items-center pr-10'>
                    <TextArea className='w-full text-gray-51 customer_project_comment_component_textarea ' name='message' value={message} placeholder={t('writeAComment', 'Escribe un comentario')} onChange={onChangeMessage} maxRows={3} />
                    <InputHidden name='CustomerProjectId' value={customerProjectData?.id} />
                    {loading === false ? <FilesClipInput name='file_url' className='flex items-center' onChange={setFiles} /> : null}
                    <ButtonDisabledOrLoader buttonIsDisabled={buttonIsDisabled} textButton={t('add', 'Añadir')} {...{ loading }} />
                </div>
            </div>
        </TwinForm>
    )
}

interface CustomProjectCommentTextAreaLogicProps {
    endLoading?: () => void
    getCustomerProject: () => Promise<void>
    commentBoxRef: MutableRefObject<any>
}

const useCustomProjectCommentTextAreaLogic = ({ endLoading, getCustomerProject, commentBoxRef }: CustomProjectCommentTextAreaLogicProps) => {
    const [message, setMessage] = useState<string>('')
    const [files, setFiles] = useState<ImageInputFile[]>([])

    const onChangeMessage = useCallback((value: string) => {
        setMessage(value)
    }, [setMessage])

    const handleSubmit: OnSubmit = useCallback(() => {
        setMessage('')
        setFiles([])
        endLoading?.()
        getCustomerProject()
        commentBoxRef.current.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [endLoading, getCustomerProject, commentBoxRef])

    return { onChangeMessage, handleSubmit, message, files, setFiles }
}

export default CustomerProjectCommentTab