import { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { ComponentWithPermissions } from '../../../../../../../baseComponents/CreateRoutering/types'
import EditCardTabs from '../../../../../../../baseComponents/EditCardTabs'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import { getBasePath } from '../../../../../../../utils/globals/link'
import { ComponentTabObject } from '../../../../../../../utils/hooks/useTabLogic'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import MainContactProfile from './Tabs/MainContactProfile'
import ContactAddresses from './Tabs/ContactAddresses'
import ContactOtherInformation from './Tabs/ContactOtherInformation'
import ContactBanks from './Tabs/ContactBanks'
import ContactContactsPersons from './Tabs/ContactContactsPersons'
import ContactProjects from './Tabs/ContactProjects'
import ContactShops from './Tabs/ContactShops'
import ContactNotes from './Tabs/ContactNotes'
import ContactGdpr from './Tabs/ContactGdpr'
import ContactFiles from './Tabs/ContactFiles'
import ContactBagOfHours from './Tabs/ContactBagOfHours'
import ContactTasks from './Tabs/ContactTasks'
import { useBasicData } from '../../../../../../../utils/hooks/useBasicData'
import { dictionaryComplexFromJsonArr, TwinDictionary } from '../../../../../../../utils/globals/dictionary'

interface SingleContactProps extends  ComponentWithPermissions {}

const SingleContact: React.FC<SingleContactProps> = ({ userPermissions }) => {
    const location = useLocation()
    const params = useParams<{ id: string, tab?: string }>()
    const { t } = useTwinTranslation()
    const { basicData, getData } = useBasicData({ params: { id: params.id }, url: '/api/app/customer/getCustomerInstance', variableToCurrentPage: 'name' })
    const { dictCustomerOrigins } = useSingleContactLogic()

    if (!params.id) {
        return null
    }
    const tabs: ComponentTabObject = {
        'main': { component: MainContactProfile, text: t('general', 'General'), permission: 'customer.profile.main', extraProps: { dictCustomerOrigins}},
        'other-information': { component: ContactOtherInformation, text: t('otherInformation', 'Otra información'), permission: 'customer.profile.main' },
        'address': { component: ContactAddresses, text: t('addresses', 'Direcciones'), permission: 'customer.profile.address' },
        'bank': { component: ContactBanks, text: t('banks', 'Bancos'), permission: 'customer.profile.bank' },
        'contacts': { component: ContactContactsPersons, text: t('contacts', 'Contactos'), permission: 'customer.profile.contactPerson' },
        'shops': { component: ContactShops, text: t('workingCenters', 'Centros de trabajo'), permission: 'customer.profile.shops' },
        'projects': { component: ContactProjects, text: t('projects', 'Proyectos'), permission: 'customer.profile.projects' },
        'bagsOfHours': { component: ContactBagOfHours, text: t('bagsOfHours', 'Bolsas de horas'), permission: 'customer.profile.bagOfHours', modules: ['tasks'] },
        'tasks': { component: ContactTasks, text: t('tasks', 'Tareas'), permission: 'customer.profile.tasks', modules: ['tasks'] },
        'notes': { component: ContactNotes, text: t('notes', 'Notas'), permission: 'customer.profile.main' },
        'files': { component: ContactFiles, text: t('files', 'Ficheros'), permission: 'customer.profile.files' },
        'gdpr': { component: ContactGdpr, text: t('gdpr', 'RGPD'), permission: 'customer.profile.gdpr' },
    }

    return (<EditCardTabs basePath={getBasePath(location.pathname, params.id)} basicData={basicData} tabs={tabs} tab={params.tab} userPermissions={userPermissions} getData={getData} />)
}



const useSingleContactLogic = () => {
    const [dictCustomerOrigins, setDictCustomerOrigins] = useState<TwinDictionary | null>(null)

    const getDictCustomerOrigins = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/customerOrigin/getAllCustomerOrigins', {})
        if (result) {
            setDictCustomerOrigins(dictionaryComplexFromJsonArr(result))
        }
    }, [setDictCustomerOrigins])

    useEffect(() => {
        getDictCustomerOrigins()
    }, [getDictCustomerOrigins])

    return { dictCustomerOrigins }
}

export default SingleContact