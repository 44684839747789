import { useCallback } from 'react'
import { useParams } from 'react-router'
import { WCalFreeDayModelType } from '@teinor/erp/types/company/WCalParent/WCal/WCalFree/WCalFreeDay'
import { ModalOpenedSetTy } from '../../../../../../../../baseComponents/Modal/types'
import { LittleModalCreateEdit } from '../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { FormRender } from '../../../../../../../../forms/FormRenderer/types'
import useTwinTranslation from '../../../../../../../../utils/hooks/useTwinTranslation'

interface ModalCreateWCFreeDaysProps {
    setOpened: ModalOpenedSetTy,
    getData: () => void
}

const ModalCreateWCFreeDays: React.FC<ModalCreateWCFreeDaysProps> = ({ setOpened, getData }) => {
    const { t } = useTwinTranslation()
    const { getDefaultDateTwinCalendar, getDefaultRandomColorWCFreeDays, id } = useModalCreateWCFreeDaysLogic()
    const fields: FormRender<WCalFreeDayModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'day',
                    label: t('day', 'Día'),
                    component: 'InputCalendarStateFull',
                    onlyValids: true,
                    required: true,
                    defaultActiveStartDate: getDefaultDateTwinCalendar(),
                },
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                },
                {
                    name: 'color',
                    label: t('color', 'Color'),
                    component: 'InputColorWithMargin',
                    defaultValue: getDefaultRandomColorWCFreeDays()
                },
                {
                    name: 'WCalFreeId',
                    value: id,
                    component: 'InputHidden'
                }
            ]
        },
    ]
    return (<LittleModalCreateEdit fields={fields} url={'/api/app/workingCalendar/workingCalendarFree/createWorkingCalendarFreeDay'} translations={{
        title: t('createWorkingCalendarFreeDays', 'Crear Día Festivo'),
        button: t('create', 'Crear')
    }} haveButtonPermissions={true} setOpened={setOpened} onSubmit={getData} />)
}

const useModalCreateWCFreeDaysLogic = () => {
    const { id, year } = useParams()
    const yearParsed = parseInt(year || '0')
    const getDefaultDateTwinCalendar = useCallback(() => {
        const currentDate = new Date()
        const defaultDate = new Date(yearParsed, currentDate.getMonth(), currentDate.getDate())
        return defaultDate
    }, [yearParsed])

    const getDefaultRandomColorWCFreeDays = useCallback(() => {
        const colors = [
            '#6299E3',
            '#90BDDA',
            '#F8A7C1',
            '#AECB6C',
            '#F88754',
            '#F5AE56',
            '#D891EE',
            '#21B37D'
        ]
        const index = Math.floor(Math.random() * colors.length)
        return colors[index]
    }, [])

    return { getDefaultDateTwinCalendar, getDefaultRandomColorWCFreeDays, id}
}

export default ModalCreateWCFreeDays