import { CPChecklistTaskModelType } from '@teinor/erp/types/company/customer/customerProject/cPChecklist/cPChecklistSection/cPChecklistTask'
import { useCallback } from 'react'
import { twinFetchPostJSON } from '../../../../../utils/globals/data'
import { ChangeCPChecklistTaskTy } from '../types'
import { SetCustomerProjectDataTy } from '../../types'
import { getUserPermissions } from '../../../../../utils/reducers/getters'
import ChecklistTask from '../../../../../baseComponents/Checklist/ChecklistTask'

interface CPChecklistTaskProps extends CPChecklistTaskLogicProps {}

const CPChecklistTask: React.FC<CPChecklistTaskProps> = ({ task, ...logic}) => {
    const { updateCPChecklistTask, deleteCPChecklistTask, userPermission } = useCPChecklistTaskLogic({ task, ...logic })
    return (
        <ChecklistTask task={task} userPermission={userPermission} onChangeTask={updateCPChecklistTask} onDeleteTask={deleteCPChecklistTask} />
    )
}

interface CPChecklistTaskLogicProps {
    posChecklist: number
    posSection: number
    posTask: number
    task: CPChecklistTaskModelType
    setCustomerProjectData: SetCustomerProjectDataTy
}

const useCPChecklistTaskLogic = ({ posChecklist, posSection, posTask, task, setCustomerProjectData }: CPChecklistTaskLogicProps) => {
    const userPermission = getUserPermissions('customer.projects.checklists')

    const handleCPChecklistTask: ChangeCPChecklistTaskTy = useCallback((field, value) => {
        setCustomerProjectData((old)=>{
            const copyOld = JSON.parse(JSON.stringify(old))
            const checklist = copyOld.CPChecklists[posChecklist]
            checklist.CPChecklistSections[posSection].CPChecklistTasks[posTask][field]= value
            return { ...copyOld }
        })
    }, [setCustomerProjectData, posChecklist, posSection, posTask])

    const updateCPChecklistTask: ChangeCPChecklistTaskTy = useCallback(async (field, value) => {
        if (value !== task[field]) {
            const result = await twinFetchPostJSON('/api/app/customer/customerProject/customerProjectChecklist/section/task/updateCPChecklistTask', { id: task.id, [field]: value })
            if (result) {
                handleCPChecklistTask(field, value)
            }
        }
    }, [task, handleCPChecklistTask])

    const deleteCPChecklistTask = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/customerProjectChecklist/section/task/deleteCPChecklistTask', { id: task.id })
        if (result) {
            setCustomerProjectData((old) => {
                const copyOld = JSON.parse(JSON.stringify(old))
                const checklist = copyOld.CPChecklists[posChecklist]
                checklist.CPChecklistSections[posSection].CPChecklistTasks.splice(posTask, 1)
                return { ...copyOld }
            })
        }
    }, [task.id, setCustomerProjectData, posChecklist, posSection, posTask])
    
    return { updateCPChecklistTask, deleteCPChecklistTask, userPermission }
}

export default CPChecklistTask