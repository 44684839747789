import { SueModelType } from '@teinor/erp/types/company/sue'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { SueCommentModelType, sueCommentUserTypesInversed } from '@teinor/erp/types/company/sue/sueComment'
import {  displayDateTime } from '../../../../../../../../../utils/globals/date'
import { ButtonDisabledOrLoader } from '../../../../../../../../../baseComponents/Button'
import useIsLoading from '../../../../../../../../../utils/hooks/useIsLoading'
import TwinForm from '../../../../../../../../../forms/TwinForm'
import { useCallback, useState } from 'react'
import { OnSubmit } from '../../../../../../../../../forms/TwinForm/types'
import TextArea from '../../../../../../../../../forms/Input/TextArea'
import { InputHidden } from '../../../../../../../../../forms/Input'
import FilesInput from '../../../../../../../../../forms/FilesInput'
import TwinTrans from '../../../../../../../../../baseComponents/TwinTrans'
import TwinIcon from '../../../../../../../../../baseComponents/TwinIcon'
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons'

type SueCommentsProps = SueCommentsLogicProps & ComponentWithPermissions  & {
    sueData: SueModelType
}

const SueComments: React.FC<SueCommentsProps> = ({ sueData, getSueData }) => {
    const {t} = useTwinTranslation()
    const { startLoading, endLoading, loading } = useIsLoading()
    const { handleSubmit, message, onChangeMessage } = useSueCommentsLogic({getSueData, endLoading})

    const renderThis: JSX.Element[] = []
    if(sueData.SueComments.length){
        for (const sueComment of sueData.SueComments) {
            renderThis.push(<SueComment key={sueComment.id} name={sueData?.name || ''} anonimous={sueData.anonimous} {...{sueComment}} />)
        }
    }

            
    return (
        <div className='flex-auto mb-20'>
            <div className='flex justify-between items-center mb-20'>
                <h2>
                    <TwinTrans transKey='messages'>Mensajes</TwinTrans>
                </h2>
            </div>
            <div className='flex flex-auto flex-col '>
                {renderThis}
            </div>
            <TwinForm className='my-20' action='/api/app/sue/addSueComment' imagepath={['sues', String(sueData.id)]} beforeSubmitHandler={startLoading} onError={endLoading} onSubmit={handleSubmit}>
                <TextArea className='w-full mr-15' name='message' value={message} onChange={onChangeMessage} />
                <InputHidden name='SueId' value={sueData?.id} />
                <div className=' flex justify-between items-center mt-20'>
                    {loading === false ? <FilesInput name='file_url' className='flex items-center' /> : null}
                    <ButtonDisabledOrLoader  buttonIsDisabled={message === ''} textButton={t('send', 'Enviar')} {...{ loading }} />
                </div>
            </TwinForm >
        </div>
    )
}


interface SueCommentsLogicProps {
    endLoading: () => void
    getSueData: () => Promise<void>
}

const useSueCommentsLogic = ({getSueData, endLoading}: SueCommentsLogicProps) => {
    const [ message, setMessage] = useState<string>('')

    const onChangeMessage = useCallback((value: string) => {
        setMessage(value)
    }, [setMessage])

    const handleSubmit: OnSubmit = useCallback(() => {
        setMessage('')
        endLoading()
        getSueData()
        
    }, [endLoading, getSueData])


    return { handleSubmit, onChangeMessage, message }
}



interface SueCommentProps extends HeaderSueCommentProps {
}

const SueComment: React.FC<SueCommentProps> = ({sueComment, anonimous, name}) => {
    const typeManager = sueComment.UserType === sueCommentUserTypesInversed['manager']
    return (
        <div className={(typeManager ? 'bg-gray-F7': 'bg-green-DB' )+  ' p-20 rounded-xl my-10 mr-10 sue_comment'}>
            <HeaderSueComment {...{ anonimous, name, sueComment }} />
            {sueComment.message}
            {sueComment.file_url?
                <a href={sueComment.file_url} className=' block text-green-21 mt-10 cursor-pointer' target='_blank' rel="noreferrer">
                    <TwinTrans transKey='downloadFile'>Descargar fichero</TwinTrans>
                    <TwinIcon className='ml-10 ' icon={faCloudDownload} />
                </a>
            : null}
        </div>
    )
}

interface HeaderSueCommentProps {
    anonimous: boolean
    name: string
    sueComment: SueCommentModelType
}

const HeaderSueComment: React.FC<HeaderSueCommentProps> = ({ sueComment, anonimous, name }) => {
    const {t} = useTwinTranslation()
    
    let author = anonimous? t('anonimous', 'Anónimo'): name
    if (sueComment.UserType === sueCommentUserTypesInversed['manager']){
        author = t('responsible', 'Responsable')
    }
    return (
        <div className='mb-6'><span className='medium12'>{author}</span>  <span className='light12'>| {displayDateTime(new Date(sueComment.createdAt))}</span></div>
    )
}


export default SueComments