import { CustomerProjectModelType } from '@teinor/erp/types/company/customer/customerProject'
import { connect } from 'react-redux'
import { useState } from 'react'
import { ConnectedProps } from 'react-redux'
import { MediumModalCreateEdit } from '../../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../../baseComponents/ModalsLayouts/types'
import { DropDownSortFunct } from '../../../../../../../../../forms/CustomSelect/DropDown'
import { FormRender } from '../../../../../../../../../forms/FormRenderer/types'
import { sortCustomerProjectStatuses } from '../../../../../../../../../specificComponents/Customers/SortCustomerProjectStatuses'
import withLoading from '../../../../../../../../../utils/hoc/withLoading'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../../../../../../../utils/reducers'
import { EmployeesPayload } from '../../../../../../../../../utils/reducers/company/employees'
import { CustomerPayload } from '../../../../../../../../../utils/reducers/customers/customer'
import { CustomerProjectTypePayload } from '../../../../../../../../../utils/reducers/customers/customerProjectTypes'
import { getAllCustomerProjectTypes } from '../../../../../../../../../utils/reducers/getters'
import { GroupPayload } from '../../../../../../../../../utils/reducers/groups/groups'
import CustomSelectSectionCustomers from '../../../../../../../../../specificComponents/Customers/CustomSelectSectionCustomers'

type ModalCreateCustomerProjectFlowProps = ModalEditComponentProps & ReduxCustomerProject & {
    customerProjectTypeId: number
}

const ModalCreateCustomerProjectFlow: React.FC<ModalCreateCustomerProjectFlowProps> = ({ customerProjectTypeId, onSubmit, setOpened, employees, groups, customers }) => {
    const { t } = useTwinTranslation()
    const { customerProjectsTypes, myProjectStatuses, setMyProjectStatusId } = useModalCreateCustomerProjectFlowLogic({ id: customerProjectTypeId })
    const fields: FormRender<CustomerProjectModelType> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'name',
                    component: 'InputWithLabelMargin',
                    label: t('name', 'Nombre'),
                    required: true
                },
                {
                    name: 'description',
                    component: 'TextArea',
                    label: t('description', 'Descripción'),
                },
                {
                    name: 'CustomerId',
                    component: 'Custom',
                    render: ({extraProps}) => <CustomSelectSectionCustomers name='CustomerId' customers={extraProps.customers} label={t('customer', 'Cliente')} required={true} />,
                    extraProps: { customers }
                },
                {
                    name: 'CustomerProjectTypeId',
                    component: 'CustomSelect',
                    label: t('projectType', 'Tipo de proyecto'),
                    items: customerProjectsTypes || {},
                    required: true,
                    readOnly: true,
                    value: customerProjectTypeId,
                    onChange: (value) => setMyProjectStatusId(value)
                },
                {
                    name: 'CurrentCProjectStatusId',
                    component: 'CustomSelect',
                    label: t('projectStatus', 'Tipo de estado'),
                    items: myProjectStatuses || [],
                    required: true,
                    DropDownComponent: DropDownSortFunct,
                    extraDropDownParams: {
                        sortFunction: (items: any) => sortCustomerProjectStatuses(items)
                    }
                },
                {
                    name: 'limit_date',
                    label: t('limitDate', 'Fecha límite'),
                    component: 'InputCalendarStateFull',
                    onlyValids: true,
                }
            ]
        },
        {
            cols: 1,
            title: t('employees', 'Empleados'),
            elements: [
                {
                    name: 'Employees',
                    component: 'MultipleCheckboxSelector',
                    subtitles: { subtitleOptions: t('notSelected', 'Sin seleccionar'), subtitleSelectedOptions: t('selected', 'Seleccionados') },
                    items: employees || {},
                }
            ]
        },
        {
            cols: 1,
            title: t('groups', 'Grupos'),
            elements: [
                {
                    name: 'Groups',
                    component: 'MultipleCheckboxSelector',
                    subtitles: { subtitleOptions: t('notSelected', 'Sin seleccionar'), subtitleSelectedOptions: t('selected', 'Seleccionados') },
                    items: groups || {},
                }
            ]
        }
    ]
    return (
        <MediumModalCreateEdit url='/api/app/customer/customerProject/createCustomerProject' fields={fields} setOpened={setOpened} onSubmit={onSubmit} translations={{
            title: t('createProject', 'Crear proyecto'),
            button: t('create', 'Crear')
        }} haveButtonPermissions={true}>
        </MediumModalCreateEdit>
    )
}

interface ModalCreateCustomerProjectFlowLogicProps {
    id: number
}

const useModalCreateCustomerProjectFlowLogic = ({ id }: ModalCreateCustomerProjectFlowLogicProps) => {
    const customerProjectsTypes = getAllCustomerProjectTypes()
    const idProjectType = id !== undefined ? String(id) : ''
    const [myProjectStatusId, setMyProjectStatusId] = useState<string>(idProjectType)
    const myProjectStatuses = myProjectStatusId ? customerProjectsTypes?.[parseInt(myProjectStatusId)].customerProjectStatusDict : []
    return { customerProjectsTypes, myProjectStatuses, setMyProjectStatusId }
}


const customerProjectDispatch = {
    setCustomerProjectTypes: (payload: CustomerProjectTypePayload) => ({ type: 'CHANGE_CUSTOMER_PROJECT_TYPES', payload }),
    setGroups: (payload: GroupPayload) => ({ type: 'CHANGE_GROUP', payload }),
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
    setCustomers: (payload: CustomerPayload) => ({ type: 'CHANGE_CUSTOMER', payload }),
}

export type ReduxCustomerProject = ConnectedProps<typeof customerProjectConnect>
const mapCustomerProjectTypesConnector = (state: AllReduxPayloads) => ({ customerProjectTypes: state.customerProjectTypes, groups: state.groups, employees: state.employees, customers: state.customers })
const customerProjectConnect = connect(mapCustomerProjectTypesConnector, customerProjectDispatch)

const customerProjectConnectLoading = withLoading(ModalCreateCustomerProjectFlow, [{ fetchUrl: '/api/app/customer/customerProject/customerProjectTypes/getAllCustomerProjectTypesFull', propName: 'customerProjectTypes', setFunctionName: 'setCustomerProjectTypes' }, { fetchUrl: '/api/app/group/getAllGroupsListing', propName: 'groups', setFunctionName: 'setGroups' }, { fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }, { fetchUrl: '/api/app/customer/getAllCustomersComplete', propName: 'customers', setFunctionName: 'setCustomers' }])

const ModalCreateCustomerProjectsConnect = customerProjectConnect(customerProjectConnectLoading)

export default ModalCreateCustomerProjectsConnect