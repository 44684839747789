import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { useLocation } from 'react-router'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import { TwinTableLink } from '../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { EditRow, CustomRenderString } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import ModalCreateCustomerB2B from './ModalCreateCustomerB2B'
import { EmployeesPayload } from '../../../../../../utils/reducers/company/employees'
import { connect, ConnectedProps } from 'react-redux'
import { AllReduxPayloads } from '../../../../../../utils/reducers'
import withLoading from '../../../../../../utils/hoc/withLoading'


interface CustomersB2BProps extends ComponentWithPermissions { }

const CustomersB2B: React.FC<CustomersB2BProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('customersB2B', 'Clientes B2B'),
        newText: t('newCustomerB2B', 'Nuevo cliente B2B'),
    }

    return (
        <BaseScreenTable TableComponent={TableCustomersB2BConnect} CreateModalComponent={ModalCreateCustomerB2B} pageData={pageData} userPermissions={userPermissions} />
    )
}

type TableCustomersB2BProps = TableComponentProps & ReduxTableCustomersB2B & { }

const TableCustomersB2B: React.FC<TableCustomersB2BProps> = ({ userPermissions, setTableInstance, setOpened, employees }) => {
    const { t } = useTwinTranslation()
    const location = useLocation().pathname
    const columns: ColumnTableSchema<CustomerModelType> = [
        {
            id: 'code',
            dataKey: 'code',
            label: t('code', 'Código'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('customerCode', 'Código de cliente') }
            },
            searchKey: 'Customer.code'
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },
            searchKey: 'Customer.name'
        },
        {
            id: 'fiscal_name',
            dataKey: 'fiscal_name',
            label: t('fiscalName', 'Nombre Fiscal'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('fiscalName', 'Nombre Fiscal') }
            }
        },
        {
            id: 'email',
            dataKey: 'email',
            label: t('email', 'Email'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },
            searchKey: 'Customer.email'
        },
        {
            id: 'phone',
            dataKey: 'phone',
            label: t('phone', 'Teléfono'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('phone', 'Teléfono') }
            },
            searchKey: 'Customer.phone'
        },
        {
            id: 'mobile',
            dataKey: 'mobile',
            label: t('mobile', 'Móvil'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('mobile', 'Móvil') }
            },
            searchKey: 'Customer.mobile'
        },
        {
            id: 'CustomerOriginId',
            dataKey: 'CustomerOriginId',
            label: t('origin', 'Origen'),
            customRender: (_parameterValue, allRowData) => <CustomRenderString value={allRowData?.CustomerOrigin?.name} />,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('origin', 'Origen') }
            },
            searchKey: 'CustomerOrigin.name',
            hideColumnByDefault: true
        },
        {
            id: 'ResponsibleEmployeeId',
            dataKey: 'ResponsibleEmployeeId',
            label: t('ourManager', 'Nuestro gestor'),
            customRender: (_parameterValue, allRowData) => <CustomRenderString value={allRowData?.ResponsibleEmployee?.fullname_short} />,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: employees || {}, label: t('ourManager', 'Nuestro gestor'), fieldName: 'fullname_short'}
            },
            hideColumnByDefault: true
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        },
    ]
    return (
        <TwinTableLink createRowDataLink={[location, ['$id', 'main']]} name='customersB2B' columns={columns} getDataFrom='/api/app/customer/getAllCustomers' extraWhereParams={{'customer_type' : 1}}  setTableInstance={setTableInstance} />
    )
}



const tableCustomersB2BDispatch = {
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
}

type ReduxTableCustomersB2B = ConnectedProps<typeof tableCustomersB2BConnect>
const mapTableCustomersB2BConnector = (state: AllReduxPayloads) => ({ employees: state.employees})
const tableCustomersB2BConnect = connect(mapTableCustomersB2BConnector, tableCustomersB2BDispatch)
const tableCustomersB2BLoading = withLoading(TableCustomersB2B, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }])
const TableCustomersB2BConnect = tableCustomersB2BConnect(tableCustomersB2BLoading)
export default CustomersB2B