import { useCallback, useEffect, useRef, useState } from 'react'
import { InputHidden, RectangularInputProps } from '..'
import TwinColor from '../../../baseComponents/TwinColor'
import useOnClickOutside from '../../../utils/hooks/useOnClickOut'
import { CustomRenderColor } from '../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { CustomSelectDropdownIcon } from '../../CustomSelect/DropDown'

export type InputColorProps = RectangularInputProps  & InputColorLogicProps & {
    colors?: string[]
}

const InputColor: React.FC<InputColorProps> = ({ className, colors, label, onChange, defaultValue, value, ...inputRest}) => {
    const readOnly = inputRest.readOnly
    const { showPicker, handleShowPicker, handleMyColor, myColor, ref } = useInputColorLogic({ onChange, defaultValue, value })
    const borderColor = showPicker ? 'border-green-43' : 'border-gray-97'
    const textColor = showPicker ? 'text-green-43' : ''
    return (
        <div className={'relative ' + (className || '')}>
            <div className={`flex cursor-pointer relative border-b ${borderColor} pb-8 mt-6 input_color_header`} onClick={readOnly? undefined : handleShowPicker}>
                <CustomRenderColor value={myColor} />
                <span className={`ml-10 ${textColor}`}>{label}</span>
                <CustomSelectDropdownIcon opened={showPicker} />
            </div>
            <InputHidden value={myColor} {...inputRest}/>
            {showPicker ? <div ref={ref} ><TwinColor className='twinColor' color={myColor} onChange={handleMyColor} {...{ colors }}/></div> : null}
        </div>
    )
}
interface InputColorLogicProps {
    defaultValue?: string
    onChange?: (value: string) => void
    value?: string
}

const useInputColorLogic = ({defaultValue, onChange, value}: InputColorLogicProps) => {
    const [showPicker, setShowPicker] = useState<boolean>(false)
    const [myColor, setMyColor] = useState<string>(value || defaultValue || 'BDBDBD')
    const ref = useRef<any>(null)

    useOnClickOutside(ref, () => setShowPicker(false))

    const handleShowPicker = useCallback(() => {
        setShowPicker((old)=>!old)
    }, [setShowPicker])

    const handleMyColor = useCallback((color: string) => {
        setMyColor(color)
        onChange?.(color)
    }, [setMyColor, onChange])

    useEffect(() => {
        if (value) {
            setMyColor(value)
        }
    }, [value])
    return { showPicker, handleShowPicker, handleMyColor, myColor, ref }
}


export interface InputColorWithMarginProps extends InputColorProps {}

export const InputColorWithMargin: React.FC<InputColorWithMarginProps> = ({className, ...res}) => {
    return (<InputColor className={'mt-16 ' + (className || '')} {...res} />)
}

export default InputColor