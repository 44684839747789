import { WhatCanYouDoInversedKeys } from '@teinor/erp/permissions'
import { PermissionModelType } from '@teinor/erp/types/company/userInner/permission'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ComponentWithPermissions } from '../../../../../../../../baseComponents/CreateRoutering/types'
import { TitleSButtonPermissioned } from '../../../../../../../../baseComponents/Layout/TitleAndRightLayout'
import VirtualTableListing from '../../../../../../../../baseComponents/TwinTable/VirtualTableListing'
import { ColumnTableSchema } from '../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import Checkbox from '../../../../../../../../forms/Checkbox'
import { twinFetchPostJSON } from '../../../../../../../../utils/globals/data'
import { dictionaryFromJsonArr } from '../../../../../../../../utils/globals/dictionary'
import { ComponentTabObject } from '../../../../../../../../utils/hooks/useTabLogic'
import useTwinTranslation from '../../../../../../../../utils/hooks/useTwinTranslation'
import { changeSuccessMessage } from '../../../../../../../../utils/reducers/reduxDispatch'
import { titleRowRender, parseDataPermissionTable, createTableDataDictionaryPermissions, addOrDeletePermissionTable, addOrDeleteAllPermissionsTable } from './function'
import { PermissionTabRow, PermissionTypeRow, TotalValuePermissionsProps, PermissionCheckboxBase } from './types'

interface PermissionTabProps extends ComponentWithPermissions {
    basicData: ComponentTabObject
 }
interface PermissionExtended extends PermissionModelType {
    text: string
}
const margins: {[key: number]: string} = {
    0: '',
    1: 'ml-20',
    2: 'ml-40',
    3: 'ml-60'
}
const PermissionTab: React.FC<PermissionTabProps> = ({ basicData, userPermissions }) => {
    const { t } = useTwinTranslation()
    const { tableData, savePermissions, handleChangePermission, handleChangeAllPermissions, GroupId } = usePermissionTabLogic()
    const columns: ColumnTableSchema<PermissionExtended> = [
        {
            id: 'idPermission',
            dataKey: 'idPermission',
            label: '',
            className:'titlePerm', 
            customRender: (parameterValue, allRowData) =><IdPermission dataKey={parameterValue} title={allRowData.title} />
        },
        {
            id: 'permission1',
            dataKey: 'permission',
            label: '',
            width: 180,
            customRender: (_parameterValue, allRowData) => <RenderPermissionsRow sectionKey={allRowData.sectionKey} GroupId={parseInt(allRowData.GroupId)} type={allRowData.type} idPermission={allRowData.idPermission} columnPermission={1} permission={allRowData.value} handleChangePermission={(permission) => handleChangePermission(permission, allRowData.rowIndex)} handleChangeAllPermissions={(permission) => handleChangeAllPermissions(permission, allRowData.rowIndex)} maxRows={allRowData.maxRows} totalValues={allRowData.totalValues} />
        },
        {
            id: 'permission2',
            dataKey: 'permission',
            label: '',
            width: 180,
            customRender: (_parameterValue, allRowData) => <RenderPermissionsRow sectionKey={allRowData.sectionKey} GroupId={parseInt(allRowData.GroupId)} type={allRowData.type} idPermission={allRowData.idPermission} columnPermission={2} permission={allRowData.value} handleChangePermission={(permission) => handleChangePermission(permission, allRowData.rowIndex)} handleChangeAllPermissions={(permission) => handleChangeAllPermissions(permission, allRowData.rowIndex)} maxRows={allRowData.maxRows} totalValues={allRowData.totalValues} />
        },
        {
            id: 'permission3',
            dataKey: 'permission',
            label: '',
            width: 180,
            customRender: (_parameterValue, allRowData) => <RenderPermissionsRow sectionKey={allRowData.sectionKey} GroupId={parseInt(allRowData.GroupId)} type={allRowData.type} idPermission={allRowData.idPermission} columnPermission={3} permission={allRowData.value} handleChangePermission={(permission) => handleChangePermission(permission, allRowData.rowIndex)} handleChangeAllPermissions={(permission) => handleChangeAllPermissions(permission, allRowData.rowIndex)} maxRows={allRowData.maxRows} totalValues={allRowData.totalValues} />
        },
        {
            id: 'permission4',
            dataKey: 'permission',
            label: '',
            width: 180,
            customRender: (_parameterValue, allRowData) => <RenderPermissionsRow sectionKey={allRowData.sectionKey} GroupId={parseInt(allRowData.GroupId)} type={allRowData.type} idPermission={allRowData.idPermission} columnPermission={4} permission={allRowData.value} handleChangePermission={(permission) => handleChangePermission(permission, allRowData.rowIndex)} handleChangeAllPermissions={(permission) => handleChangeAllPermissions(permission, allRowData.rowIndex)} maxRows={allRowData.maxRows} totalValues={allRowData.totalValues} />
        },
    ]
    return (
        <TitleSButtonPermissioned title={basicData?.[GroupId]?.text as string} buttonText={t('save', 'Guardar')} onClick={savePermissions} userPermissions={userPermissions} permission='update' >
            <div className='flex-auto'>
                <VirtualTableListing name='permissionTab' tableData={tableData} columns={columns} headerHeight={0} rowRenderer={titleRowRender}/>
            </div>
        </TitleSButtonPermissioned>
    )
}

export type DictPermissions = {[key: string]: WhatCanYouDoInversedKeys | undefined}
const usePermissionTabLogic = () => {
    const [tableData, setTableData] = useState<PermissionTabRow[] | null>(null)
    const params = useParams<{ id: string }>()
    const GroupId = params?.id || '0'
    const { t } = useTwinTranslation()

    const permissionsTranslateStr = useMemo(() => {
        return JSON.stringify({
            generic: {
                title: t('content', 'Contenido'),
                permissions: {
                    'workingCenter': t('workingCenters', 'Centros de trabajo'),
                    'workingCenter.main': t('workingCenterMain', 'Centros de Trabajo Principal'),
                    'workingCenter.workingCenterType': t('workingCenterWorkingCenterType', ' Tipos  Centros de Trabajo'),
                    'workingCenter.employee': t('workingCenterEmployee', 'Empleados Centros de trabajo'),
                    'sue': t('sues', 'Denuncias'),
                    'customer': t('customers', 'Clientes'),
                    'customer.customerGroup': t('customerTypes', 'Tipo de clientes'),
                    'customer.customerProjectType': t('projectsTypes', 'Tipo de proyectos'),
                    'customer.origin': t('customerOrigin', 'Origen de cliente'),
                    'customer.projects': t('customerProjects', 'Proyectos de clientes'),
                    'customer.projects.main': t('customerProjectsMain', 'Proyectos de clientes - Principal'),
                    'customer.projects.checklists': t('customerProjectsChecklistsPermissions', 'Proyectos de clientes - Listas'),
                    'customer.profile': t('customersProfilePermissions', 'Ficha de clientes'),
                    'customer.profile.main': t('customersProfilePermissionsMain', 'Ficha de clientes - Principal'),
                    'customer.profile.address': t('customersProfilePermissionsAddress', 'Ficha de clientes - Direcciones'),
                    'customer.profile.bank': t('customersProfilePermissionsBanks', 'Ficha de clientes - Bancos'),
                    'customer.profile.contactPerson': t('customersProfilePermissionsContactPersons', 'Ficha de clientes - Personas de contacto'),
                    'customer.profile.shops': t('customersProfilePermissionsWorkingCenters', 'Ficha de clientes - Centros de trabajo'),
                    'customer.profile.projects': t('customersProfilePermissionsProjects', 'Ficha de clientes - Proyectos'),
                    'customer.profile.bagOfHours': t('customersProfilePermissionsBagOfHours', 'Ficha de clientes - Bolsas de horas'),
                    'customer.profile.tasks': t('customersProfilePermissionsTasks', 'Ficha de clientes - Tareas'),
                    'customer.profile.files': t('customersProfilePermissionsFiles', 'Ficha de clientes - Ficheros'),
                    'customer.profile.gdpr': t('customersProfilePermissionsGDPR', 'Ficha de clientes - RGPD'),
                    'employee': t('employees', 'Empleados'),
                    'employee.main': t('employeesMainTab', 'Empleados Pestaña Principal'),
                    'employee.files': t('employeesFilesTab', 'Empleados Pestaña Ficheros'),
                    'employee.holidays': t('employeesHolidayTab', 'Empleados Pestaña Vacaciones '),
                    'employee.absences': t('employeesAbsenceTab', 'Empleados Pestaña Ausencias'),
                    'employee.payslip': t('employeesPayslipTab', 'Empleados Pestaña Nóminas'),
                    'employee.expenses': t('employeesExpensesTab', 'Empleados Pestaña Gastos'),
                    'group': t('groups', 'Grupos'),
                    'group.permissions': t('groupPermissions', 'Permisos de Grupo'),
                    'group.users': t('groupUsers', 'Usuarios de grupo'),
                    'WCal': t('timeControl', 'Control horario'),
                    'WCal.main': t('workingCalendarMain', 'Calendario laboral Principal'),
                    'WCal.schedule': t('workingCalendarSchedule', 'Horario'),
                    'WCal.free': t('workingCalendarFree', 'Calendario de días festivos'),
                    'WCal.holiday.bags': t('holidayBags', 'Bolsas de vacaciones'),
                    'WCal.holiday.usedDays': t('holidayListing', 'Listado de vacaciones'),
                    'WCal.checkEmployeeMonth': t('controlWorkingMonth', 'Control calendario del mes'),
                    'WCal.special': t('workingCalendarSpecialDays', 'Calendarios de días especiales'),
                    'WCal.absences': t('employeeAbsences', 'Ausencias de empleados'),
                    'WCal.absences.control': t('employeeAbsencesControl', 'Control ausencias de empleados'),
                    'WCal.absences.type': t('employeeAbsenceTypes', 'Tipos de ausencias de empleado'),
                    'expenses': t('employeesExpenses', 'Gastos empleados'),
                    'expenses.main': t('employeesExpensesListing', 'Listado gastos empleados'),
                    'expenses.rowType': t('employeesExpensesTypes', 'Tipos de gastos empleados'),
                    'config': t('config', 'Configuración'),
                    'config.parameter': t('parameters', 'Parámetros'),
                    'config.lang': t('langs', 'Idiomas'),
                    'config.currency': t('currencies', 'Monedas'),
                    'config.customerProjects': t('customerProjects', 'Proyectos del cliente'),
                    'config.customerProjects.templateChecklist': t('templateLists', 'Plantilla de lista'),
                    'task': t('tasks', 'Tareas'),
                    'task.main': t('taskMain', 'Tarea principal'),
                    'task.bagOfHours': t('bagOfHours', 'Bolsas de horas'),
                    'task.CField': t('customFields', 'Campos personalizados'),
                    'task.taskType': t('taskType', 'Tipo de tarea'),
                    'task.repository': t('taskRepository', 'Repositorio'),
                }
            },
            special: {
                title: t('otherPermissions', 'Otros permisos'),
                permissions: {
                    'employeeView': t('seeAllEmployee', 'Ver todos los empleados'),
                    'employeeView.workingCenterGroup': t('seeAllEmployeesMyGroupsAndWC', 'Ver todos los empleados de mis grupos de mis centros de trabajo'),
                    'employeeView.group': t('seeAllEmployeesMyGroups', 'Ver todos los empleados de mis grupos'),
                    'employeeView.workingCenter': t('seeAllEmployeesMyWorkingCenters', 'Ver todos los empleados de mi centro de trabajo'),
                    'taskView': t('tasks', 'Tareas'),
                    'taskView.getAllTasks': t('allTasks', 'Todas las tareas'),
                    'taskView.getTasksFromGroupUsers': t('tasksOtherGroupsUsers', 'Tareas otros empleados de tu grupo'), 
                    'taskView.getTasksOtherGroup': t('tasksOtherGroup', 'Tareas de otros grupos'),
                    'taskView.getTasksOtherWorkingCenter': t('TasksOtherWorkingCenter', 'Tareas de otros centros de trabajo'),
                    'groupView': t('seeAllGroups', 'Ver todos los grupos'),
                    'skipRestrictTimeControlAccess': t('skipRestrictTimeControlAccess', 'Evitar restricción de tiempo de acceso'),
                }
            },
        })
    }, [t])
    
    const getTableData = useCallback(async () => {
        const result: PermissionModelType[] = await twinFetchPostJSON('/api/app/permission/getAllPermissions', { GroupId })
        const permissionsTranslate = JSON.parse(permissionsTranslateStr)
        const resultDict = dictionaryFromJsonArr(result, 'idPermission', 'permission')
        const dataParsed = parseDataPermissionTable(permissionsTranslate, resultDict, GroupId)
        setTableData(dataParsed)
    }, [setTableData, permissionsTranslateStr, GroupId])

    const savePermissions = useCallback(async () => {
        const successMessage = t('updatedAllPermissions', 'Permisos actualizados')
        const dictTableData = createTableDataDictionaryPermissions(tableData)
        const petition = await twinFetchPostJSON('/api/app/permission/updateAllPermissions', {
            permissions: dictTableData,
            GroupId
        })
        if (petition) {
            changeSuccessMessage(successMessage)
            getTableData()
        }
    }, [getTableData, GroupId, tableData, t])

    const handleChangePermission = useCallback((newPermission: WhatCanYouDoInversedKeys, index: number) => {
        setTableData((old) => {
            if (!old) {
                return null
            }
            if (parseInt(String(GroupId)) !== 1) {
                const data = addOrDeletePermissionTable(old, { newPermission, index })    
                return data
            }
            return old
        })
    }, [setTableData, GroupId])

    const handleChangeAllPermissions = useCallback((newPermission: WhatCanYouDoInversedKeys, index: number) => {
        setTableData((old) => {
            const data = addOrDeleteAllPermissionsTable(old, { newPermission, index })
            return data
        })
    }, [setTableData])

    useEffect(() => {
        getTableData()
    }, [getTableData])

    return { tableData, getTableData, savePermissions, handleChangePermission, handleChangeAllPermissions, GroupId }
}

interface IdPermissionProps  {
    dataKey: string
    title: string
}

const IdPermission: React.FC<IdPermissionProps> = ({title, dataKey}) => {
    return (<div className={margins[dataKey?.match(/[.]/g)?.length || 0]}>{title}</div>)
}

interface RenderPermissionsRowProps {
    columnPermission: WhatCanYouDoInversedKeys
    permission?: number
    sectionKey: string
    idPermission: string
    type: PermissionTypeRow
    GroupId: number
    handleChangePermission: (permission: WhatCanYouDoInversedKeys) => void
    handleChangeAllPermissions: (permission: WhatCanYouDoInversedKeys) => void
    maxRows: number
    totalValues: TotalValuePermissionsProps
}

const RenderPermissionsRow: React.FC<RenderPermissionsRowProps> = ({ columnPermission, permission, handleChangePermission, type, handleChangeAllPermissions, GroupId, maxRows, totalValues, sectionKey }) => {
    if (type === 'section' || (sectionKey === 'special' &&  columnPermission !== 1)) {
        return null
    }
    if (type === 'config') {
        let checkMaxColumns = totalValues?.[columnPermission] === maxRows ? true : false
        if (parseInt(String(GroupId)) === 1) {
            checkMaxColumns = true
        } 
        return <PermissionAllCheckbox GroupId={GroupId} columnPermission={sectionKey === 'special'? 4:  columnPermission} handleChangeAllPermissions={handleChangeAllPermissions} checked={checkMaxColumns} {...{ sectionKey }} />
    }   
    
    return (<PermissionCheckbox checked={permission? columnPermission <= permission : false} columnPermission={columnPermission} permission={permission} handleChangePermission={handleChangePermission}  />)
}

interface PermissionCheckboxProps extends PermissionCheckboxBase {
    handleChangePermission: (permission: WhatCanYouDoInversedKeys) => void
    permission?: number
}
const PermissionCheckbox: React.FC<PermissionCheckboxProps> = ({ checked, columnPermission, handleChangePermission }) => {   
    return (<Checkbox label={''} checked={checked} onChange={() => handleChangePermission(columnPermission)} />)
}

interface PermissionAllCheckboxProps extends PermissionCheckboxBase {
    sectionKey: string
    GroupId: number
    handleChangeAllPermissions: (permission: WhatCanYouDoInversedKeys) => void
}

const PermissionAllCheckbox: React.FC<PermissionAllCheckboxProps> = ({ GroupId, handleChangeAllPermissions, columnPermission, checked, sectionKey }) => {    
    const {t} = useTwinTranslation()
    const titlesConfig: {[key: number]: string} = {
        1: t('read', 'Leer'), 
        2: t('create', 'Crear'),
        3: t('update', 'Actualizar'),
        4: sectionKey ==='special'? t('havePermissions', 'Tiene permisos'): t('delete', 'Eliminar')
    }
    if(sectionKey === 'special'){
        titlesConfig[1] = t('see', 'Ver')
    }
    return <Checkbox label={titlesConfig[columnPermission]} onChange={() => { if (GroupId !== 1) { handleChangeAllPermissions(columnPermission) } }} checked={checked} />
}

export default PermissionTab