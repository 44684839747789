import { useCallback } from 'react'
import TChecklistTask from '../TChecklistTask'
import { TChecklistModelType } from '@teinor/erp/types/company/config/customerProject/tChecklist'
import { TChecklistSectionModelType } from '@teinor/erp/types/company/config/customerProject/tChecklist/tChecklistSection'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import { getUserPermissions } from '../../../../../../../utils/reducers/getters'
import ChecklistSection from '../../../../../../../baseComponents/Checklist/ChecklistSection'

interface TChecklistSectionProps extends TChecklistSectionLogicProps { }


const TChecklistSection: React.FC<TChecklistSectionProps> = ({ section, ...logic }) => {
    const { updateNameSection, addTChecklistTask, deleteTChecklistSection, userPermission } = useTChecklistSectionLogic({ section, ...logic })
    const renderThis: JSX.Element[] = []
    if (section.TChecklistTasks?.length) {
        for (const key in section.TChecklistTasks) {
            const task = section.TChecklistTasks[key]
            renderThis.push(<TChecklistTask key={task.id} posTask={parseInt(key)} task={task} {...logic} />)
        }
    }
    return (
        <div className='mb-30'>
            <ChecklistSection section={section} userPermission={userPermission} onChangeSection={updateNameSection} onDeleteSection={deleteTChecklistSection} onAddTask={addTChecklistTask} />
            {renderThis.length ?
                <div className='ml-16'>
                    <div className='border-b border-gray-EE' />
                    {renderThis}
                </div>
                :
                null
            }
        </div>
    )
}

interface TChecklistSectionLogicProps {
    posSection: number
    section: TChecklistSectionModelType
    setDataChecklist: React.Dispatch<React.SetStateAction<TChecklistModelType>>
}

const useTChecklistSectionLogic = ({ posSection, section, setDataChecklist }: TChecklistSectionLogicProps) => {
    const userPermission = getUserPermissions('config.customerProject.templateChecklist')

    const handleChangeNameSection = useCallback((value: string) => {
        setDataChecklist((old) => {
            const copyOld = JSON.parse(JSON.stringify(old))
            if (copyOld.TChecklistSections && copyOld.TChecklistSections.length) {
                copyOld.TChecklistSections[posSection].name = value
            }
            return { ...copyOld }
        })
    }, [setDataChecklist, posSection])

    const updateNameSection = useCallback(async (value: string) => {
        if (value !== section.name){
            const result = await twinFetchPostJSON('/api/app/config/templateChecklist/section/updateTChecklistSection', { id: section.id, name: value })
            if (result) {
                handleChangeNameSection(value)
            }
        }
    }, [section.id, section.name, handleChangeNameSection])

    const addTChecklistTask = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/config/templateChecklist/section/task/createTChecklistTask', { TChecklistSectionId: section.id, name: '' })
        if (result) {
            setDataChecklist((old) => {
                const copyOld = JSON.parse(JSON.stringify(old))
                if (copyOld.TChecklistSections && copyOld.TChecklistSections.length) {
                    const mySection = copyOld.TChecklistSections[posSection]
                    if (!mySection?.TChecklistTasks) {
                        copyOld.TChecklistSections[posSection].TChecklistTasks = []
                    }
                    copyOld.TChecklistSections[posSection].TChecklistTasks.push({ ...result })
                }
                return { ...copyOld }
            })
        }
    }, [section.id, setDataChecklist, posSection])

    const deleteTChecklistSection = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/config/templateChecklist/section/deleteTChecklistSection', { id: section.id })
        if (result) {
            setDataChecklist((old) => {
                const copyOld = JSON.parse(JSON.stringify(old))
                if (copyOld.TChecklistSections && copyOld.TChecklistSections.length) {
                    copyOld.TChecklistSections.splice(posSection, 1)
                }
                return { ...copyOld }
            })
        }
    }, [posSection, section.id, setDataChecklist])

    return { updateNameSection, addTChecklistTask, deleteTChecklistSection, userPermission }
}
export default TChecklistSection