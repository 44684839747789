import { TwinParameters } from '@teinor/erp/types/company/config/parameter'
import { ComponentWithPermissions } from '../../../../../../../baseComponents/CreateRoutering/types'
import SingleFormLayout from '../../../../../../../baseComponents/Layout/SingleFormLayout'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import { valueOrDefaultValue } from '../../../../../../../utils/globals/valueOrDefaultValue'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { dictTimeZones } from '@teinor/erp/types/globals/timeZone'
import { GroupPayload } from '../../../../../../../utils/reducers/groups/groups'
import withLoading from '../../../../../../../utils/hoc/withLoading'
import { ConnectedProps, connect } from 'react-redux'
import { AllReduxPayloads } from '../../../../../../../utils/reducers'
import { getISOCurrencyMain } from '../../../../../../../utils/reducers/getters'

type MainParametersProps = ComponentWithPermissions & ReduxMainParameters & {
    basicData: TwinParameters
    getData: () => Promise<void>
}

interface TwinParametersCustom extends TwinParameters {
    textForRef: string
}

const MainParameters: React.FC<MainParametersProps> = ({ basicData, getData, userPermissions, groups }) => {
    const { t } = useTwinTranslation()
    const onOff = {
        'on': { name: t('on', 'Activado') },
        'off': { name: t('disabled', 'Desactivado') },
    }
    const fields: FormRender<TwinParametersCustom> = [
        {
            cols: 3,
            title: t('general', 'General'),
            elements: [
                {
                    name: 'timeZone',
                    label: t('timeZone', 'Zona Horaria'),
                    component: 'CustomSelectWithSearchBar',
                    items: dictTimeZones,
                },
            ]
        },
        {
            cols: 3,
            title: t('dataLoseParameters', 'Parámetros de pérdida de datos'),
            elements: [
                {
                    name: 'showUnsaveDataModal',
                    label: t('showUnsaveDataModal', 'Mostrar "Tienes datos sin guardar"'),
                    component: 'CustomSelect',
                    items: onOff
                },
            ]
        },
        {
            cols: 3,
            title: t('workingCalendarParameters', 'Parámetros de control horario'),
            elements: [
                {
                    name: 'needHolidayApprove',
                    label: t('needHolidayApprove', 'Las vacaciones necesitan ser aprobadas'),
                    component: 'CustomSelect',
                    items: onOff
                },
                {
                    name: 'approveHolidayGroupId',
                    label: t('groupNotificationHolidaysAbsences', 'Grupo que recibe las not. de vacaciones/ausencias'),
                    component: 'CustomSelect',
                    items: groups || {}
                },
                {
                    name: 'needAbsenceApprove',
                    label: t('needAbsencesApprove', 'Las ausencias necesitan ser aprobadas'),
                    component: 'CustomSelect',
                    items: onOff
                },
                {
                    name: 'requireUbicationOnWorking',
                    label: t('requireUbicationOnWorking', 'Ubicación necesaria para fichar'),
                    component: 'CustomSelect',
                    items: onOff
                },
                {
                    name: 'restrictTimeControlAccess',
                    label: t('restrictTimeControlAccess', 'Restricción de acceso por control horario'),
                    component: 'CustomSelect',
                    items: onOff
                },
                {
                    name: 'restrictTimeControlAccessMinutesMargin',
                    label: t('restrictTimeControlAccessMarginInHours', 'Margen de restricción de acceso en horas'),
                    component: 'InputHour',
                    value: String(basicData?.restrictTimeControlAccessMinutesMargin)
                },
            ]
        },
        {
            cols: 3,
            title: t('expensesParameters', 'Parámetros de gastos'),
            elements: [
                {
                    name: 'kmPrice',
                    label: t('kmPrice', 'Precio del Km'),
                    component: 'InputPriceStateFull',
                    CurrencyId: getISOCurrencyMain(),
                    defaultValue: parseFloat(String(basicData.kmPrice)) || 0
                },
                {
                    name: 'approveEmployeeExpenseGroupId',
                    label: t('groupNotificationExpenses', 'Grupo que recibe las not. de gastos'),
                    component: 'CustomSelect',
                    items: groups || {}
                },
            ]
        },
        {
            cols: 3,
            title: t('taskParameters', 'Parámetros de tareas'),
            elements: [
                {
                    name: 'assignTaskYourWorkingCenterByDefault',
                    label: t('assignYourWorkingCenterByDefault', 'Asignar tu centro de trabajo por defecto'),
                    component: 'CustomSelect',
                    items: onOff
                },
                {
                    name: 'canUseOtherProjectBags',
                    label: t('workInOtherCustomersBagsOfHours', 'Trabajar en otras bolsas de horas del cliente'),
                    component: 'CustomSelect',
                    items: onOff
                },
            ]
        },
        {
            cols: 3,
            title: t('customersParameters', 'Parámetros de clientes'),
            elements: [
                {
                    name: 'automaticCreateCustomerTaskProject',
                    label: t('automaticCreateCustomerTaskProject', 'Crear automáticamente un proyecto en el cliente'),
                    component: 'CustomSelect',
                    items: onOff
                },
                {
                    name: 'codeCustomerToShow',
                    label: t('codeCustomerToShow', 'Código cliente para mostrar'),
                    component: 'CustomSelect',
                    items: {
                        0: { name: t('id', 'ID') },
                        1: { name: t('codeCustomer', 'Código de cliente') },
                    }
                },
            ]
        },
    ]
    const parsedFields = valueOrDefaultValue(fields, userPermissions, basicData)
    return (
        <SingleFormLayout action='/api/app/config/parameter/updateParameters'  onSubmit={getData} sections={parsedFields} userPermissions={userPermissions}/>
    )
}

const mainParametersDispatch = {
    setGroups: (payload: GroupPayload) => ({ type: 'CHANGE_GROUP', payload }),
}

export type ReduxMainParameters = ConnectedProps<typeof mainParametersConnect>
const mapMainParametersConnector = (state: AllReduxPayloads) => ({ groups: state.groups })
const mainParametersConnect = connect(mapMainParametersConnector, mainParametersDispatch)

const mainParametersConnectLoading = withLoading(MainParameters, [{ fetchUrl: '/api/app/group/getAllGroups', propName: 'groups', setFunctionName: 'setGroups' }])

const MainParametersConnect = mainParametersConnect(mainParametersConnectLoading)


export default MainParametersConnect