import { Fragment, useCallback, useEffect, useState } from 'react'
import AppLayout from '../../../../baseComponents/AppLayout'
import { twinCountries } from '../../../../utils/globals/countries'
import { connectToSocket } from '../../../../utils/globals/socket'
import ErpLoaderEmployeeModal from './ErpLoaderEmployeeModal'
import { checkIfEmployeeIsValid, getAndChangeCompany, getAndChangeConfig, getAndChangeNotifications, getMyWorkingTask } from './functions'
import { ErpLoaderLoaded } from './types'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import LoadingSpinner from '../../../../baseComponents/LoaderDecider/LoadingSpinner'
import ErpLoaderCompanyModal from './ErpLoaderCompanyModal'
import { signOutUser } from '../../../../utils/reducers/reduxDispatch'

interface ErpLoaderProps {
    children?: React.ReactNode
}

const ErpLoader: React.FC<ErpLoaderProps> = ({ children }) => {
    const { loaded, setLoaded, onChooseCompany } = useErpLoaderLogic()
    if (!loaded) {
        return <ERPLoadingScreen />
    }
    return (
        <Fragment>
            {loaded === 'companyLeft' && <ErpLoaderCompanyModal loaded={loaded} setLoaded={onChooseCompany} />}
            {loaded === 'employeeLeft' && <ErpLoaderEmployeeModal loaded={loaded} setLoaded={setLoaded} />}
            {loaded === true ? children : <AppLayout routes={[]}></AppLayout>}
        </Fragment>
    )
}

const useErpLoaderLogic = () => {
    const [loaded, setLoaded] = useState<ErpLoaderLoaded>(false)
    const loadCompany = useCallback(async () => {
        await Promise.all([
            getAndChangeCompany(),
            getAndChangeConfig(),
            twinCountries(),
            getMyWorkingTask(),
        ])
        connectToSocket()
        getAndChangeNotifications()
    }, [])
    const getStartedValues = useCallback(async () => {
        const isValid = await checkIfEmployeeIsValid()
        if (isValid?.status === 202) {
            setLoaded('companyLeft')
        } else if (isValid?.status === 200) {
            if (isValid.text === 'true') {
                await loadCompany()
                setLoaded(true)
            } else {
                setLoaded('employeeLeft')
            }
        } else if (isValid?.status === 422) {
            await signOutUser()
        } else if (isValid?.status === 423) {
            setLoaded(true)
        }
    }, [loadCompany])

    const onChooseCompany = useCallback(() => {
        getStartedValues()
    }, [getStartedValues])


    useEffect(() => {
        getStartedValues()
    }, [getStartedValues])

    return { loaded, setLoaded, onChooseCompany }
}

const ERPLoadingScreen: React.FC = () => {
    const { t } = useTwinTranslation()
    return (
        <div className='erp_screen_loader'>
            <h1>{t('loadingERP', 'Cargando ERP...')}</h1>
            <LoadingSpinner />
        </div>
    )
}

export default ErpLoader