import { EmployeeModelType } from '@teinor/erp/types/company/userInner/employee'
import { ConnectedProps, connect } from 'react-redux'
import { useLocation } from 'react-router'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import { permissionCheck } from '../../../../../../baseComponents/PermissionChecker/function'
import { TwinTableLink } from '../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { CustomRenderStateName, CustomRenderString, EditRow } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import withLoading from '../../../../../../utils/hoc/withLoading'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../../../../utils/reducers'
import { WorkingCenterPayload } from '../../../../../../utils/reducers/company/workingCenters'
import { GroupPayload } from '../../../../../../utils/reducers/groups/groups'
import ModalCreateEmployee from './ModalCreateEmployee'

interface EmployeesProps extends ComponentWithPermissions { }

const Employees: React.FC<EmployeesProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('employees', 'Empleados'),
        newText: t('newEmployee', 'Nuevo empleado'),
    }

    return (
        <BaseScreenTable TableComponent={TableEmployeeConnect} CreateModalComponent={ModalCreateEmployee} pageData={pageData} userPermissions={userPermissions} />
    )
}

type TableEmployeesProps = TableComponentProps & ReduxWorkingCenters & { }

const TableEmployees: React.FC<TableEmployeesProps> = ({ userPermissions, setTableInstance, setOpened, workingCenters }) => {
    const { t } = useTwinTranslation()
    const location = useLocation().pathname
    const columns: ColumnTableSchema<EmployeeModelType> = [
        {
            id: 'name',
            dataKey: 'fullname_short',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            }
        },
        {
            id: 'address',
            dataKey: 'address',
            label: t('address', 'Dirección'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('address', 'Dirección'), }
            }
        },
        {
            id: 'additional_address',
            dataKey: 'additional_address',
            label: t('additional_address_2', 'Dirección 2'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('additional_address', 'Dirección adicional'), }
            }
        },
        {
            id: 'city',
            dataKey: 'city',
            label: t('city', 'Ciudad'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('city', 'Ciudad'), }
            }
        },
        {
            id: 'state',
            dataKey: 'state',
            label: t('state', 'Provincia'),
            customRender: (parameterValue, allRowData) => <CustomRenderStateName stateId={parameterValue} allRowData={allRowData} />,
        },
        {
            id: 'defaultWorkingCenterId',
            dataKey: 'defaultWorkingCenterId',
            label: t('workingCenter', 'Centro de trabajo'),
            customRender: (parameterValue) => <CustomRenderString value={workingCenters?.[parameterValue as any]?.name || ''} />
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />,
            havePermission: permissionCheck(userPermissions, 'update')
        }
    ]
    return (
        <TwinTableLink  createRowDataLink={[location, ['$id', 'main']]} name='employees' columns={columns} getDataFrom='/api/app/employee/getAllEmployees' onRowClick={(_id, rowData) => setOpened({
            type: 'edit',
            allRowData: rowData
        })} setTableInstance={setTableInstance} />
    )
}

const workingCentersDispatch = {
    setWorkingCenters: (payload: WorkingCenterPayload) => ({ type: 'CHANGE_WORKINGCENTER', payload }),
    setGroups: (payload: GroupPayload) => ({ type: 'CHANGE_GROUP', payload }),
}

export type ReduxWorkingCenters = ConnectedProps<typeof workingCentersConnect>
const mapWorkingCentersConnector = (state: AllReduxPayloads) => ({ workingCenters: state.workingCenters, groups: state.groups })
const workingCentersConnect = connect(mapWorkingCentersConnector, workingCentersDispatch)

const workingCentersConnectLoading = withLoading(TableEmployees, [{ fetchUrl: '/api/app/workingCenter/getAllWorkingCentersComplete', propName: 'workingCenters', setFunctionName: 'setWorkingCenters' }, { fetchUrl: '/api/app/group/getAllGroupsListing', propName: 'groups', setFunctionName: 'setGroups' }])

const TableEmployeeConnect = workingCentersConnect(workingCentersConnectLoading)


export default Employees