import FingerprintJS from '@fingerprintjs/fingerprintjs';
import store, { appStore } from '.';
import { FingerPrintAction } from './globals/fingerprint';
import  jscookie from 'js-cookie'
import { twinFetchPostJSON } from '../globals/data';
import { EmployeeParamsActionWorkingCenter, UserAction, UserActionWorkingCenter, UserCookie, UserPayload } from './globals/user';
import { ChangeCurrentPageAction, SetCurrentPageVariableAction } from './globals/currentPage';
import { twinCountries, twinStates } from '../globals/countries';
import { CountriesAction } from './globals/countries';
import { Company, CompanyAction } from './globals/company';
import { Config, ConfigAction } from './globals/config';
import { ChangePopupErrorMessageAction, ChangePopupPermanentErrorMessageAction, ChangePopupSuccessMessageAction} from './globals/popupMessage';
import { AddAntiUnsaveAction, DeleteAntiUnsaveAction, ClearAntiUnsaveAction, ShowAntiUnsaveAction, HideAntiUnsaveAction } from './globals/antiUnsave';
import { TwinEmployeeParametersDict } from '@teinor/erp/types/company/userInner/employeeParameter';
import { EmployeeNotificationModelType } from '@teinor/erp/types/company/userInner/employee/employeeNotification';
import { LastNotificationAction } from './company/lastNotification';
import { AddBottomNavAppAction, ChangeBottomNavAppAction, ClearBottomNavAppAction, DeleteBottomNavAppAction } from './globals/bottomNavApp';
import { nanoid } from 'nanoid';
import { ChangeRestrictTimeControlAccessAction } from './globals/restrictTimeControlAccess';
import socket from '../globals/socket';

export const changeFingerPrint = async (): Promise<void> => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const visitorId = result.visitorId;
    const mydispatch: FingerPrintAction = {
        type: 'CHANGE_FINGERPRINT',
        payload: visitorId
    }
    appStore.dispatch(mydispatch)
}

export const setUserIfCookieValid = async () => {
    const cookie = jscookie.get('twin_jwt_user')
    if (cookie) {
        const cookieParsed = JSON.parse(cookie)
        await changeUserByUserCookie(cookieParsed)
    }
}

const changeUserByUserCookie = async (userCookie: UserCookie) => {
    const user = await getUserIfTokenValid(userCookie.token)
    if (user) {
        await changeUser({ ...userCookie, extraData: {...user, WindowId: user.id + nanoid()} })
    } else {
        jscookie.remove('twin_jwt_user')
    }
}

export const getUserIfTokenValid = async (authToken: string) => {
    return await twinFetchPostJSON('/api/user/getUserInstance', {haveToLog: true}, { authToken })
}

export const changeUser = async (userPayload: UserPayload) => {
    const mydispatch: UserAction = {
        type: 'CHANGE_USER',
        payload: userPayload
    }
    await appStore.dispatch(mydispatch)
}

export const setUserByLogin = async (userCookie: UserCookie, remember?: boolean) => {
    setUserCookie({ ...userCookie, remember }, remember)
    await changeUserByUserCookie(userCookie)
}

export const setUserCookie = (cookie: UserCookie, remember?: boolean) => {
    if (remember) {
        const ONE_YEAR_FROM_NOW = new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 365))
        jscookie.set('twin_jwt_user', JSON.stringify(cookie), { expires: ONE_YEAR_FROM_NOW, secure: false, sameSite: 'strict' })
    } else {
        jscookie.set('twin_jwt_user', JSON.stringify(cookie), { secure: false, sameSite: 'strict' })
    }
}

export const signOutUser = async (): Promise<void> => {
    socket.removeAllListeners()
    socket.emit('leaveFromCompany', socket.id)
    jscookie.remove('twin_jwt_user')
    const mydispatch: UserAction = {
        type: 'CHANGE_USER',
        payload: null
    }
    await appStore.dispatch(mydispatch)
    const mydispatchReset = {
        type: 'RESET_APP',
        payload: null
    }
    await store.dispatch(mydispatchReset)
}

export const changeCurrentPage = async (routes: string[]): Promise<void> => {
    const mydispatch: ChangeCurrentPageAction = {
        type: 'CHANGE_CURRENTPAGE',
        payload: {
            routes
        }
    }
    await appStore.dispatch(mydispatch)
}

export const setVariablesToCurrentPage = async (variables: string[]): Promise<void> => {
    const mydispatch: SetCurrentPageVariableAction = {
        type: 'SET_CURRENTPAGE_VARIABLES',
        payload: variables
    }
    await appStore.dispatch(mydispatch)
}

export const changeCountries = async (): Promise<void> => {
    const countries = await twinCountries()
    const result: any = {}
    for (const country of countries) {
        const myStates: any = {}
        const states = await twinStates(country.countryCode)
        for (const k in states) {
            myStates[states[k].id] = k
        }
        result[country.countryCode] = { name: country.countryName, code: country.countryCode, states, myStates }
    }
    const mydispatch: CountriesAction = {
        type: 'CHANGE_COUNTRY',
        payload: result
    }
    await appStore.dispatch(mydispatch)
}

export const changeCompany = async (company: Company) => {
    const mydispatch: CompanyAction = {
        type: 'CHANGE_COMPANY',
        payload: company
    }
    await appStore.dispatch(mydispatch)
}

export const changeConfig = async (config: Config) => {
    const mydispatch: ConfigAction = {
        type: 'CHANGE_CONFIG',
        payload: config
    }
    await appStore.dispatch(mydispatch)
}

export const changeErrorMessage = async (error: string | true) => {
    const mydispatch: ChangePopupErrorMessageAction = {
        type: 'CHANGE_ERROR_MSG',
        payload: error
    }
    await appStore.dispatch(mydispatch)
}
export const changePermanentErrorMessage = async (error: string | true) => {
    const mydispatch: ChangePopupPermanentErrorMessageAction = {
        type: 'CHANGE_PERMANENT_ERROR_MSG',
        payload: error
    }
    await appStore.dispatch(mydispatch)
}

export const changeRestrictTimeControlAccess = async (value: null | true) => {
    const mydispatch: ChangeRestrictTimeControlAccessAction = {
        type: 'CHANGE_RESTRICT_TC_ACCESS',
        payload: value
    }
    await appStore.dispatch(mydispatch)
}

export const changeSuccessMessage = async (error: string | true) => {
    const mydispatch: ChangePopupSuccessMessageAction = {
        type: 'CHANGE_SUCCESS_MSG',
        payload: error
    }
    await appStore.dispatch(mydispatch)
}

export const addAntiUnsaveElement = async (element: AddAntiUnsaveAction['payload']) => {
    const mydispatch: AddAntiUnsaveAction = {
        type: 'ADD_UNSAVECHANGE',
        payload: element
    }
    await appStore.dispatch(mydispatch)
}

export const deleteAntiUnsaveElement = async (element: DeleteAntiUnsaveAction['payload']) => {
    const mydispatch: DeleteAntiUnsaveAction = {
        type: 'DELETE_UNSAVECHANGE',
        payload: element
    }
    await appStore.dispatch(mydispatch)
}

export const clearAntiUnsaveElement = async () => {
    const mydispatch: ClearAntiUnsaveAction = {
        type: 'CLEAR_UNSAVECHANGE',
    }
    await appStore.dispatch(mydispatch)
}

export const showAntiUnsaveModal = async (payload: ShowAntiUnsaveAction['payload']) => {
    const mydispatch: ShowAntiUnsaveAction = {
        type: 'SHOW_UNSAVECHANGE',
        payload
    }
    await appStore.dispatch(mydispatch)
}

export const hideAntiUnsaveModal = async () => {
    const mydispatch: HideAntiUnsaveAction = {
        type: 'HIDE_UNSAVECHANGE',
    }
    await appStore.dispatch(mydispatch)
}

export const changeCurrentWorkingCenter = async (workingCenter: number) => {
    const mydispatch: UserActionWorkingCenter = {
        type: 'CHANGE_USERWORKINGCENTER',
        payload: workingCenter
    }
    await appStore.dispatch(mydispatch)
}

export const changeEmployeeParams = async (payload: TwinEmployeeParametersDict) => {
    const mydispatch: EmployeeParamsActionWorkingCenter = {
        type: 'CHANGE_EMPLOYEE_PARAMS',
        payload
    }
    await appStore.dispatch(mydispatch)
}
export const changeLastNotification = async (payload: EmployeeNotificationModelType | null) => {
    const mydispatch: LastNotificationAction = {
        type: 'CHANGE_LAST_NOTIFICATION',
        payload: payload || null
    }
    await appStore.dispatch(mydispatch)
}
export const addBottomNavAppElement = async (element: AddBottomNavAppAction['payload']) => {
    const mydispatch: AddBottomNavAppAction = {
        type: 'ADD_BOTTOMNAVAPP',
        payload: element
    }
    await appStore.dispatch(mydispatch)
}
export const changeBottomNavAppElement = async (element: ChangeBottomNavAppAction['payload']) => {
    const mydispatch: ChangeBottomNavAppAction = {
        type: 'CHANGE_BOTTOMNAVAPP',
        payload: element
    }
    await appStore.dispatch(mydispatch)
}
export const deleteBottomNavAppElement = async (element: DeleteBottomNavAppAction['payload']) => {
    const mydispatch: DeleteBottomNavAppAction = {
        type: 'DELETE_BOTTOMNAVAPP',
        payload: element
    }
    await appStore.dispatch(mydispatch)
}
export const clearBottomNavAppElement = async () => {
    const mydispatch: ClearBottomNavAppAction = {
        type: 'CLEAR_BOTTOMNAVAPP',
    }
    await appStore.dispatch(mydispatch)
}
