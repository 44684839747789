import { TChecklistModelType } from '@teinor/erp/types/company/config/customerProject/tChecklist'
import { LittleModalCreateEdit } from '../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalComponentProps } from '../../../../../../baseComponents/ModalsLayouts/types'
import { FormRender } from '../../../../../../forms/FormRenderer/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'

interface ModalCreateTChecklistProps extends ModalComponentProps {}

const ModalCreateTChecklist: React.FC<ModalCreateTChecklistProps> = ({...rest}) => {
    const {t} = useTwinTranslation()
    const fields: FormRender<TChecklistModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
            ]
        }
    ]
    return (<LittleModalCreateEdit fields={fields} url={'/api/app/config/templateChecklist/createTChecklist'} translations={{
        title: t('createTemplateChecklist', 'Crear plantilla de lista'),
        button: t('create', 'Crear')
    }} haveButtonPermissions={true} {...rest} />)
}

export default ModalCreateTChecklist