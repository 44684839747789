import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import SingleFormLayout from '../../../../../../../../../baseComponents/Layout/SingleFormLayout'
import { FormRender } from '../../../../../../../../../forms/FormRenderer/types'
import { valueOrDefaultValue } from '../../../../../../../../../utils/globals/valueOrDefaultValue'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { useTypeIdNumber } from '../../../../../../../../../utils/hooks/useTypeIdNumber'
import { getActiveLangs } from '../../../../../../../../../utils/reducers/getters'
import { EmployeesPayload } from '../../../../../../../../../utils/reducers/company/employees'
import { connect, ConnectedProps } from 'react-redux'
import withLoading from '../../../../../../../../../utils/hoc/withLoading'
import { AllReduxPayloads } from '../../../../../../../../../utils/reducers'
import { useMemo } from 'react'
import { addDictionaryExtraLine, TwinDictionary } from '../../../../../../../../../utils/globals/dictionary'


type MainCustomerB2BProfileProps = ComponentWithPermissions & ReduxMainCustomerB2BProfile & {
    basicData: CustomerModelType | null
    getData: () => Promise<void>
    extraProps?: TwinDictionary
}

const MainCustomerB2BProfile: React.FC<MainCustomerB2BProfileProps> = ({ basicData, userPermissions, getData, employees, extraProps }) => {
    const { t } = useTwinTranslation()
    const { typesIdNumber } = useTypeIdNumber()
    const myEmployees = useMemo(() => addDictionaryExtraLine(JSON.parse(JSON.stringify(employees || {}))), [employees])

    const fields: FormRender<CustomerModelType> = [
        {
            cols: 2,
            title: t('generalInformation', 'Información general'),
            elements: [

                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'fiscal_name',
                    label: t('fiscalName', 'Nombre fiscal'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'type_id_number',
                    label: t('typeIdNumber', 'Tipo de documento identificación'),
                    component: 'CustomSelect',
                    items: typesIdNumber,
                    value: basicData?.type_id_number,
                    required: true
                },
                {
                    name: 'id_number',
                    label: t('id_number', 'DNI'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'code',
                    label: t('customerCode', 'Código de Cliente'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'customer_card',
                    label: t('customerCard', 'Tarjeta de cliente'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'responsable_name',
                    label: t('responsableName', 'Nombre del responsable'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'ResponsibleEmployeeId',
                    label: t('ourManager', 'Nuestro gestor'),
                    component: 'CustomSelectWithSearchBar',
                    items: myEmployees || {},
                    fieldName: 'fullname_short',
                    sortItems: true
                }
            ]
        },
        {
            cols: 2,
            title: t('contactInformation', 'Datos de contacto'),
            elements: [
                {
                    name: 'phone',
                    label: t('phone', 'Teléfono'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'mobile',
                    label: t('mobile', 'Móvil'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'email',
                    label: t('email', 'Email'),
                    component: 'InputWithLabelMargin',
                    type: 'email',
                },
                {
                    name: 'web',
                    label: t('web', 'Página web'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'LangId',
                    label: t('language', 'Idioma'),
                    component: 'CustomSelect',
                    items: getActiveLangs(),
                },
                {
                    name: 'CustomerOriginId',
                    label: t('origin', 'Origen'),
                    component: 'CustomSelect',
                    items: extraProps?.dictCustomerOrigins || {},
                },
                {
                    name: 'newsletter',
                    label: t('newsletter', 'Newsletter'),
                    component: 'CheckboxMargin',
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: basicData?.id
                }
            ]
        },
    ]
    const parsedFields = valueOrDefaultValue(fields, userPermissions, basicData)

    return (
        <SingleFormLayout action='/api/app/customer/updateCustomer' onSubmit={getData} sections={parsedFields} userPermissions={userPermissions} />
    )
}

const mainCustomerB2BProfileDispatch = {
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
}

export type ReduxMainCustomerB2BProfile = ConnectedProps<typeof mainCustomerB2BProfileConnect>
const mapMainCustomerB2BProfileTypesConnector = (state: AllReduxPayloads) => ({ employees: state.employees })
const mainCustomerB2BProfileConnect = connect(mapMainCustomerB2BProfileTypesConnector, mainCustomerB2BProfileDispatch)

const mainCustomerB2BProfileConnectLoading = withLoading(MainCustomerB2BProfile, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }])

const ModalCreateMainCustomerB2BProfileConnect = mainCustomerB2BProfileConnect(mainCustomerB2BProfileConnectLoading)

export default ModalCreateMainCustomerB2BProfileConnect