import { WCalHolidayBagModelType } from '@teinor/erp/types/company/WCalParent/WCal/WCalHolidayBag'
import { useParams } from 'react-router'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import { permissionCheck } from '../../../../../../../../../baseComponents/PermissionChecker/function'
import BaseScreenTableInner from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner'
import { TableComponentBaseScreenInnerProps } from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner/types'
import { CustomRenderDate, EditRow } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import ModalCEEmployeeHolidaysBag from './ModalCEEmployeeHolidaysBag'
import TwinTable from '../../../../../../../../../baseComponents/TwinTable'


interface EmployeeHolidaysBagProps extends ComponentWithPermissions {
}

const EmployeeHolidaysBag: React.FC<EmployeeHolidaysBagProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()
    const params = useParams<{ id: string }>()
    const id = params.id
    const pageData = {
        title: t('holidaysBags', 'Bolsas de vacaciones'),
        newText: t('addEmployeeHolidaysBag', 'Añadir bolsa de vacaciones'),
    }

    return (
        <BaseScreenTableInner TableComponent={TableEmployeeHolidaysBag} extraWhereParams={{ 'EmployeeId': id }} pageData={pageData} CreateModalComponent={ModalCEEmployeeHolidaysBag} EditModalComponent={ModalCEEmployeeHolidaysBag} userPermissions={userPermissions} />
    )
}

interface TableEmployeeHolidaysProps extends TableComponentBaseScreenInnerProps {
}

const TableEmployeeHolidaysBag: React.FC<TableEmployeeHolidaysProps> = ({ setTableInstance, userPermissions, extraWhereParams, setOpened }) => {
    const { t } = useTwinTranslation()
    const columns: ColumnTableSchema<WCalHolidayBagModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            width: 75
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },
        },
        {
            id: 'from',
            dataKey: 'from',
            label: t('availableFrom', 'Disponible desde'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />
        },
        {
            id: 'to',
            dataKey: 'to',
            label: t('expires', 'Caduca'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />
        },
        {
            id: 'originalDays',
            dataKey: 'originalDays',
            label: t('originalDays', 'Días iniciales'),
        },
        {
            id: 'leftDays',
            dataKey: 'leftDays',
            label: t('leftDays', 'Días restantes'),
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />,
            havePermission: permissionCheck(userPermissions, 'update')
        }
    ]
    return (
        <TwinTable name='employeeHolidaysBag' columns={columns} defaultOrder={{ order: 'desc', defaultOrderField: 'from' }} getDataFrom='/api/app/workingCalendar/workingCalendarHolidayBag/getAllWorkingCalendarHolidayBags'
            onRowClick={(_id, rowData) => setOpened({
                type: 'edit',
                allRowData: rowData
            })}
            setTableInstance={setTableInstance} extraWhereParams={extraWhereParams} />
    )
}

export default EmployeeHolidaysBag