import ModalDeleteFilled from '../../../../baseComponents/ModalsLayouts/ModalDeleteFilled'
import { ModalEditComponentProps } from '../../../../baseComponents/ModalsLayouts/types'
import { useFetchText } from '../../../../utils/hooks/useFetchText'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'


interface ModalDesactiveCustomerProjectProps extends ModalEditComponentProps { }

const ModalDesactiveCustomerProject: React.FC<ModalDesactiveCustomerProjectProps> = ({ setOpened, onSubmit, allRowData }) => {
    const { t } = useTwinTranslation()
    const { handleFetchText } = useFetchText({
        onSubmit,
        url: '/api/app/customer/customerProject/updateCustomerProject',
        data: {
            id: allRowData?.id,
            active: false
        }
    })
    return (
        <ModalDeleteFilled opened={true} setOpened={setOpened} onCancel={() => setOpened(null)} onAccept={handleFetchText} translations={{
            title: t('sureUnactiveThisCustomerProject', '¿Seguro que quieres desactivar este proyecto?'),
            subtitle: t('onceUnactivedProjectNotAbleInList', 'Una vez desactivado no te aparecerá en el listado de proyectos'),
            buttonAccept: t('disable', 'Desactivar')
        }} />
    )
}

export default ModalDesactiveCustomerProject