import { WCalHolidayBagModelType } from '@teinor/erp/types/company/WCalParent/WCal/WCalHolidayBag'
import { ConnectedProps, connect } from 'react-redux'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import { permissionCheck } from '../../../../../../baseComponents/PermissionChecker/function'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { CustomRenderString, CustomRenderDate, EditRow } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import withLoading from '../../../../../../utils/hoc/withLoading'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../../../../utils/reducers'
import { EmployeesPayload } from '../../../../../../utils/reducers/company/employees'
import ModalCEWCHolidayBag from './ModalCEWCHolidayBag'
import TwinTable from '../../../../../../baseComponents/TwinTable'

interface WorkingCalendarHolidayBagProps extends ComponentWithPermissions { }

const WorkingCalendarHolidayBag: React.FC<WorkingCalendarHolidayBagProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()
    const pageData = {
        title: t('holidaysBags', 'Bolsas de vacaciones'),
        newText: t('newHolidayBags', 'Nueva bolsa de vacaciones'),
    }

    return (
        <BaseScreenTable TableComponent={TableWorkingCalendarHolidayBagConnect} CreateModalComponent={ModalCEWCHolidayBag} EditModalComponent={ModalCEWCHolidayBag} pageData={pageData} userPermissions={userPermissions} />
    )
}

type TableWorkingCalendarHolidayBagProps = TableComponentProps & ReduxEmployees & { }

const TableWorkingCalendarHolidayBag: React.FC<TableWorkingCalendarHolidayBagProps> = ({userPermissions, setTableInstance, setOpened, employees }) => {
    const { t } = useTwinTranslation()
    const columns: ColumnTableSchema<WCalHolidayBagModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            width: 75
        },
        {
            id: 'name',
            dataKey: 'name',
            searchKey: 'WCalHolidayBag.name',
            label: t('name', 'Nombre'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },
        },
        {
            id: 'EmployeeId',
            dataKey: 'EmployeeId',
            label: t('employee', 'Empleado'),
            customRender: (parameterValue) => <CustomRenderString value={employees?.[parameterValue as any]?.fullname_short || ''} />,
            searchKey: 'Employee.fullname_short',
            searchSingleKey: 'EmployeeId',
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: employees || {}, label: t('employee', 'Empleado'), fieldName: 'fullname_short' },
            },
        },
        {
            id: 'from',
            dataKey: 'from',
            label: t('availableFrom', 'Disponible desde'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
            sortable: true
        },
        {
            id: 'to',
            dataKey: 'to',
            label: t('expires', 'Caduca'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
            sortable: true
        },
        {
            id: 'originalDays',
            dataKey: 'originalDays',
            label: t('originalDays', 'Días iniciales'),
            sortable: true
        },
        {
            id: 'leftDays',
            dataKey: 'leftDays',
            label: t('leftDays', 'Días restantes'),
            sortable: true
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />,
            havePermission: permissionCheck(userPermissions, 'update')
        }
    ]
    return (
        <TwinTable name='workingCalendarHolidayBag' columns={columns} getDataFrom='/api/app/workingCalendar/workingCalendarHolidayBag/getAllWorkingCalendarHolidayBags' onRowClick={(_id, rowData) => setOpened({
                type: 'edit',
                allRowData: rowData
            })} setTableInstance={setTableInstance} />
    )
}

const employeesDispatch = {
    setEmployees: (payload: EmployeesPayload) =>
    ({type: 'CHANGE_EMPLOYEE', payload})
}

export type ReduxEmployees = ConnectedProps<typeof employeesConnect>
const mapEmployeesConnector = (state: AllReduxPayloads) => ({ employees: state.employees })
const employeesConnect = connect(mapEmployeesConnector, employeesDispatch)

const employeesConnectLoading = withLoading(TableWorkingCalendarHolidayBag, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees'}] )

const TableWorkingCalendarHolidayBagConnect = employeesConnect(employeesConnectLoading)

export default WorkingCalendarHolidayBag