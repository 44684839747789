import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { ColumnTableSchema } from '../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import { TaskHelperModelType } from '@teinor/erp/types/company/task/taskHelper'
import TwinTable from '../../../../../../../baseComponents/TwinTable'
import { ModalCETaskHelperCreator } from './ModalCETaskHelperCreator'
import { DeleteRow, EditRowNotLink } from '../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { getUserInfo } from '../../../../../../../utils/reducers/getters'
import BaseScreenTableInner from '../../../../../../../baseComponents/TwinTable/BaseScreenTableInner'
import { TableComponentBaseScreenInnerProps } from '../../../../../../../baseComponents/TwinTable/BaseScreenTableInner/types'
import ModalDeleteTaskHelperCreator from './ModalDeleteTaskHelperCreator'

const EmployeeTaskHelperCreator = () => {
    const { t } = useTwinTranslation()
    const user = getUserInfo()
 
    const pageData = {
        title: t('taskHelpers', 'Ayudantes de tareas'),
        newText: t('newTaskHelper', 'Nuevo ayudante de tareas'),
    }

    return (
        <BaseScreenTableInner TableComponent={TableEmployeeTaskHelperCreator} pageData={pageData} EditModalComponent={ModalCETaskHelperCreator} CreateModalComponent={ModalCETaskHelperCreator}
        DeleteModalComponent={ModalDeleteTaskHelperCreator} userPermissions={{ n: 4, permission: 'delete' }} extraWhereParams={{ 'EmployeeId': user?.Employee?.id }} />
    )
}

type TableEmployeeTaskCreatorProps = TableComponentBaseScreenInnerProps  &  {}


const TableEmployeeTaskHelperCreator: React.FC<TableEmployeeTaskCreatorProps> = ({ setTableInstance, setOpened, extraWhereParams }) => {
    const { t } = useTwinTranslation()

    const columns: ColumnTableSchema<TaskHelperModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            width: 75
        },
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'id'),
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRowNotLink key={'edit' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'edit', allRowData })} />,
        },
        {
            id: 'delete',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('delete', 'Eliminar'),
            customRender: (parameterValue, allRowData) => <DeleteRow key={'delete' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'delete', allRowData })} />
        }
    ]
    return (
        <TwinTable name='employeeTaskCreator' onRowClick={(_idk, allRowData) => setOpened({ type: 'edit', allRowData })} columns={columns} getDataFrom='/api/app/task/helper/getAllTaskHelpers' setTableInstance={setTableInstance} extraWhereParams={extraWhereParams} />
    )
}

export default EmployeeTaskHelperCreator