import { TwinDictionary } from '../../../utils/globals/dictionary';
import { stringMatchWithSearch } from '../../../utils/globals/search';
import { CustomSelectSectionItems, ItemCustomSelectDropDownRowSection } from './types';

export const sectionsToArrayForDropDown = (sections: CustomSelectSectionItems, fieldName: string ='name') => {
    const sectionItems: ItemCustomSelectDropDownRowSection[] = []
    const index: TwinDictionary = {}
    for (const section of sections) {
        sectionItems.push({value: '', text: section.name, isSection: true})
        for (const value in section.childs) {
            const text = section.childs[value][fieldName]
            sectionItems.push({value, text})
            index[value] = { value, text }
        }
    }
    return {sectionItems, index}
}

export const filterCustomSelectSectionsItems = (searchValue: string, items: ItemCustomSelectDropDownRowSection[]) => {
    const filteredItems: ItemCustomSelectDropDownRowSection[] = []
    for (const key in items) {
        const item = items[key]
        if (stringMatchWithSearch(searchValue, String(item.text)) || item.isSection || (item.hiddenText && stringMatchWithSearch(searchValue, String(item.hiddenText)))) {
            filteredItems.push(item)
        }
    }
    const nItems = filteredItems.length
    const finalResult : ItemCustomSelectDropDownRowSection[] = []
    for (const key in filteredItems) {
        const item = filteredItems[key]
        const keyN = parseInt(key)
        if(item.isSection){
            const isTitleLastItem = keyN === nItems - 1
            if(isTitleLastItem){
                continue
            }
            const haveNoChilds = filteredItems[keyN+1].isSection
            if(haveNoChilds){
                continue
            }
        }            
        finalResult.push(item)
    }
    return finalResult
}