import { faChevronDown, faChevronUp, faLock } from '@fortawesome/pro-light-svg-icons'
import { useCallback, useMemo, useState } from 'react'
import SearchBar from '../../../baseComponents/SearchBar'
import { TwinAutoSizer, TwinList } from '../../../baseComponents/TwinTable/Adapters'
import TwinIcon, { TwinIconProp } from '../../../baseComponents/TwinIcon'
import { TwinDictionary } from '../../../utils/globals/dictionary'
import { ChangeValueFunc, Modify } from '../../../utils/globals/types'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { filterCustomSelectItems, sortItems, sortItemsNumbers } from './functions'
import { CustomSelectDropDownBaseProps, CustomSelectDropDownBasePropsMod, CustomSelectDropDownProps, CustomSelectDropDownRowProps, CustomSelectDropDownUnsortedProps, DropDownComponentType, DropDownDeciderProps, ItemCustomSelectDropDownRow } from './types'

interface CustomSelectDropDownSortedNumbersProps extends CustomSelectDropDownBaseProps { }
export const CustomSelectDropDownSortedNumbers: React.FC<CustomSelectDropDownSortedNumbersProps> = ({ items, children, ...rest }) => {
    const itemsSorted = useMemo(() => sortItemsNumbers(items, rest.fieldName), [items, rest.fieldName])
    return (<CustomSelectDropDown items={itemsSorted} {...rest} rowRenderer={({ ...rowRenderProps }) => {
        return (
            <CustomSelectDropDownRow currentvalue={rest.value} onChange={rest.onChange} item={{ value: itemsSorted[rowRenderProps.index].value, text: itemsSorted[rowRenderProps.index].text }} {...rowRenderProps} />
        )
    }}>{children}</CustomSelectDropDown>)
}

export const DropDownWithSearchBar: DropDownComponentType = ({ ...props }) => {
    const { t } = useTwinTranslation()
    const { searchValue, changeSearchValue, filteredItems } = useDropDownWithSearchBarLogic(props.items)
    const Component = DefaultDropDownDecider({ sortItems: props.sortItems, sortFunction: props.sortFunction })
    return (
        <Component {...props} items={filteredItems}>
            <SearchBar className='custom_select_search_bar' placeholder={t('search', 'Buscar')} onChange={changeSearchValue} {...{ searchValue }} />
        </Component>
    )
}

export const useDropDownWithSearchBarLogic = (items: TwinDictionary) => {
    const [searchValue, setSearchValue] = useState<string>('')
    const filteredItems = useMemo(() => filterCustomSelectItems(searchValue, items), [searchValue, items])
    const changeSearchValue: ChangeValueFunc = useCallback((myvalue) => {
        setSearchValue(myvalue)
    }, [setSearchValue])

    return { searchValue, changeSearchValue, filteredItems }
}

interface CustomSelectDropdownIconProps {
    opened: boolean
    readOnly?: boolean
    icon?: TwinIconProp
}

export const CustomSelectDropdownIcon: React.FC<CustomSelectDropdownIconProps> = ({ icon, opened, readOnly }) => {
    let myIcon = faLock
    if (!readOnly) {
        myIcon = opened ? faChevronUp : faChevronDown
    }
    return (<TwinIcon icon={icon || myIcon} className='ml-auto mr-10' />)
}

export const DropDownDecider = ({ DropDownComponent, sortItems, sortFunction }: DropDownDeciderProps) => {
    if (DropDownComponent) {
        return DropDownComponent
    }
    return DefaultDropDownDecider({ sortItems, sortFunction })
}

interface DefaultDropDownDeciderProps {
    sortItems?: boolean
    children?: React.ReactNode
    sortFunction?: (items: TwinDictionary) => ItemCustomSelectDropDownRow[]
}

export const DefaultDropDownDecider = ({ sortItems, sortFunction }: DefaultDropDownDeciderProps) => {
    if (sortFunction || sortItems) {
        return CustomSelectDropDownSorted
    }
    return CustomSelectDropDownUnsorted
}

interface CustomSelectDropDownSortedProps extends CustomSelectDropDownBaseProps { }
export const CustomSelectDropDownSorted: React.FC<CustomSelectDropDownSortedProps> = ({ items, children, fieldName, sortFunction, ...rest }) => {
    const itemsSorted = useMemo(() => sortFunction ? sortFunction(items) : sortItems(items, fieldName), [items, fieldName, sortFunction])
    return (<CustomSelectDropDown items={itemsSorted} {...rest} rowRenderer={({ ...rowRenderProps }) => {
        return (
            <CustomSelectDropDownRow currentvalue={rest.value} onChange={rest.onChange} item={{ value: itemsSorted[rowRenderProps.index].value, text: itemsSorted[rowRenderProps.index].text }} {...rowRenderProps} />
        )
    }}>{children}</CustomSelectDropDown>)
}

export const CustomSelectDropDownUnsorted: React.FC<CustomSelectDropDownUnsortedProps> = ({ items, children, fieldName = 'name', ...rest }) => {
    const keys = useMemo(() => Object.keys(items), [items])
    return (<CustomSelectDropDown items={keys} {...rest} rowRenderer={({ ...rowRenderProps }) => {
        return (
            <CustomSelectDropDownRow currentvalue={rest.value} onChange={rest.onChange} item={{ value: keys[rowRenderProps.index], text: items[keys[rowRenderProps.index]]?.[fieldName] }}  {...rowRenderProps} />
        )
    }}>{children}</CustomSelectDropDown>)
}

const ROW_HEIGHT = 25

export const CustomSelectDropDown: React.FC<CustomSelectDropDownProps> = ({ items, children, rowRenderer, maxItems = 8 }) => {
    return (
        <div className='custom_select_dropdown'>
            {children}
            <TwinAutoSizer>
                {({ width }) => (
                    <TwinList className='bg-white shadow z-1'
                        height={items.length > maxItems ? ROW_HEIGHT * maxItems : ROW_HEIGHT * items.length}
                        rowCount={items.length}
                        rowHeight={ROW_HEIGHT}
                        rowRenderer={rowRenderer}
                        width={width}
                    />
                )}
            </TwinAutoSizer>
        </div>
    )
}

export const CustomSelectDropDownRow: React.FC<CustomSelectDropDownRowProps> = ({ className, onChange, ...rest }) => {
    const { changeValue, text, value, nameComplete } = useDropDownRowLogic({ onChange, ...rest })
    return (
        <div className={'custom_select_value cursor-pointer flex justify-between items-center bg-white regular12 text-gray-51 hover:bg-gray-F7 px-15 ' + (className || '')} onClick={changeValue} {...rest} {...value} title={text as any}>
            <span className='w-full twin_elipsis' title={nameComplete}>{text}</span>
        </div>
    )
}

export const useDropDownRowLogic = ({ item, onChange }: CustomSelectDropDownRowProps) => {
    const value = item.value
    const text = item.text
    const hiddenText = item?.hiddenText
    const nameComplete = item.text + (hiddenText? ( ' - ' + hiddenText) : '')
    const changeValue = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        onChange(value, e)
    }, [onChange, value])

    return { changeValue, text, value: { selectValue: value }, nameComplete }

}


type DropDownSortFunctProps = Modify<CustomSelectDropDownBasePropsMod, {
}>

export const DropDownSortFunct: React.FC<DropDownSortFunctProps> = ({ extraParams, items, ...rest }) => {
    const sortFunction = extraParams?.sortFunction
    const itemsSorted = useMemo(() => sortFunction?.(items) || items, [items, sortFunction])
    return (<CustomSelectDropDown items={itemsSorted} {...rest} rowRenderer={({ ...rowRenderProps }) => {
        return (
            <CustomSelectDropDownRow currentvalue={rest.value} onChange={rest.onChange} item={{ value: itemsSorted[rowRenderProps.index].value, text: itemsSorted[rowRenderProps.index].text }} {...rowRenderProps} />
        )
    }} />)
}
