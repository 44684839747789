import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import ModalEditCustomerProject from '../../../../../../../../../../specificComponents/Customers/ModalEditCustomerProject'

interface ModalUserNotificationsProjectsProps extends ModalEditComponentProps {
}

const ModalUserNotificationsProjects: React.FC<ModalUserNotificationsProjectsProps> = ({ userPermissions, setOpened, allRowData }) => {
    return (
        <ModalEditCustomerProject allRowData={{...allRowData, id: allRowData?.SubId}} userPermissions={userPermissions} setOpened={setOpened} onSubmit={()=>setOpened(null)}  />
    )
}

export default ModalUserNotificationsProjects