import { CustomerGroupModelType } from '@teinor/erp/types/company/customer/customerGroup'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import TwinTable from '../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import ModalCECustomerOrigin from './ModalCECustomerOrigin'
import { EditRow } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'


interface CustomerOriginsProps extends ComponentWithPermissions { }

const CustomerOrigins: React.FC<CustomerOriginsProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('CustomerOrigins', 'Orígenes de cliente'),
        newText: t('newCustomerOrigin', 'Nuevo origen de cliente'),
    }

    return (
        <BaseScreenTable TableComponent={TableCustomerOrigins} CreateModalComponent={ModalCECustomerOrigin} EditModalComponent={ModalCECustomerOrigin} pageData={pageData} userPermissions={userPermissions} />
    )
}

interface TableCustomerOriginsProps extends TableComponentProps { }

const TableCustomerOrigins: React.FC<TableCustomerOriginsProps> = ({ setTableInstance , setOpened}) => {
    const { t } = useTwinTranslation()
    const columns: ColumnTableSchema<CustomerGroupModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            width: 75,
            sortable: true,
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        },
    ]
    return (
        <TwinTable columns={columns} name='customerOrigins' getDataFrom='/api/app/customer/customerOrigin/getAllCustomerOrigins' setTableInstance={setTableInstance} onRowClick={(_id, rowData) => setOpened({
            type: 'edit',
            allRowData: rowData
        })} />
    )
}

export default CustomerOrigins