import { EmployeeAbsenceModelType } from '@teinor/erp/types/company/userInner/employee/employeeAbsence'
import { ConnectedProps, connect } from 'react-redux'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import { DisplayStatusWithoutIcon } from '../../../../../../baseComponents/Displays/DisplayStatus'
import { permissionCheck } from '../../../../../../baseComponents/PermissionChecker/function'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { CustomRenderString, CustomRenderDate, EditRow } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import withLoading from '../../../../../../utils/hoc/withLoading'
import useStatusRequestTypes from '../../../../../../utils/hooks/useStatusRequestTypes'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../../../../utils/reducers'
import { EmployeesPayload } from '../../../../../../utils/reducers/company/employees'
import { EmployeeAbsenceTypePayload } from '../../../../../../utils/reducers/company/employeeAbsenceTypes'
import ModalManageEmployeeAbsenceRequest from './ModalManageEmployeeAbsenceRequest'
import ModalCreateEmployeeAbsenceRequest from './ModalCreateEmployeeAbsenceRequest'
import { TwinDictionary } from '../../../../../../utils/globals/dictionary'
import TwinTable from '../../../../../../baseComponents/TwinTable'

interface EmployeeAbsencesRequestsProps extends ComponentWithPermissions { }

const EmployeeAbsencesRequests: React.FC<EmployeeAbsencesRequestsProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()
    const pageData = {
        title: t('absences', 'Ausencias'),
        newText: t('newAbsence', 'Nueva ausencia'),
    }
    return (
        <BaseScreenTable TableComponent={TableEmployeeAbsencesRequestsBagConnect} pageData={pageData} userPermissions={userPermissions} EditModalComponent={ModalManageEmployeeAbsenceRequest} CreateModalComponent={ModalCreateEmployeeAbsenceRequest} />
    )
}

type TableEmployeeAbsencesRequestsProps = TableComponentProps & ReduxEmployees & {}


const TableEmployeeAbsencesRequests: React.FC<TableEmployeeAbsencesRequestsProps> = ({ userPermissions, setTableInstance, setOpened, employees, employeeAbsenceTypes }) => {
    const { t } = useTwinTranslation()
    const statusRequestTypes = useStatusRequestTypes()

    const columns: ColumnTableSchema<EmployeeAbsenceModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            width: 75
        },
        {
            id: 'EmployeeIdRequest',
            dataKey: 'EmployeeId',
            label: t('employee', 'Empleado'),
            searchKey: 'fullname_short',
            searchSingleKey: 'EmployeeId',
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: employees as TwinDictionary, label: t('employee', 'Empleado'), fieldName: 'fullname_short' }
            },
            customRender: (_parameterValue, allRowData) => <CustomRenderString value={employees?.[allRowData?.EmployeeId as any]?.fullname_short || ''} />
        },
        {
            id: 'EmployeeAbsenceTypeId',
            dataKey: 'EmployeeAbsenceTypeId',
            label: t('absenceType', 'Tipo de ausencia'),
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: employeeAbsenceTypes as TwinDictionary, label: t('absenceType', 'Tipo de ausencia') }
            },
            customRender: (parameterValue, allRowData) => <CustomRenderString value={allRowData?.EmployeeAbsenceType?.name} />,
        },
        {
            id: 'ManagerEmployeeId',
            dataKey: 'ManagerEmployeeId',
            label: t('managedBy', 'Gestionado por'),
            customRender: (parameterValue) => <CustomRenderString value={(employees?.[parameterValue as any]?.fullname_short|| '')} />
        },
        {
            id: 'dateStart',
            dataKey: 'dateStart',
            label: t('dateStart', 'Fecha inicio'),
            sortable: true,
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />
        },
        {
            id: 'dateEnd',
            dataKey: 'dateEnd',
            label: t('dateEnd', 'Fecha fin'),
            sortable: true,
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />
        },
        {
            id: 'approved',
            dataKey: 'approved',
            label: t('status', 'Estado'),
            customRender: (parameterValue) => <RenderUsedDayStatus value={parameterValue} />,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: statusRequestTypes, label: t('status', 'Estado') }
            },
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />,
            havePermission: permissionCheck(userPermissions, 'update')
        }
    ]
    return (
        <TwinTable name='EmployeeAbsencesRequestsUsedDays' columns={columns} getDataFrom='/api/app/workingCalendar/employeeAbsence/getAllEmployeeAbsences' onRowClick={(_id, rowData) => setOpened({
            type: 'edit',
            allRowData: rowData
        })} setTableInstance={setTableInstance} defaultOrder={{ defaultOrderField :'dateStart', order: 'desc'}} />
    )
}

interface RenderBuyOrderStatusProps {
    value: string
}

const RenderUsedDayStatus: React.FC<RenderBuyOrderStatusProps> = ({ value }) => {
    const status = useStatusRequestTypes()
    const parsedValue = parseInt(value)
    return <DisplayStatusWithoutIcon colorText={status[parsedValue].colorText} text={status[parsedValue].name} />
}

const employeesDispatch = {
    setEmployees: (payload: EmployeesPayload) =>({ type: 'CHANGE_EMPLOYEE', payload }),
    setEmployeeAbsenceTypes: (payload: EmployeeAbsenceTypePayload) => ({ type: 'CHANGE_EMPLOYEEABSENCETYPE', payload }),
}

export type ReduxEmployees = ConnectedProps<typeof employeesConnect>
const mapEmployeesConnector = (state: AllReduxPayloads) => ({ employees: state.employees, employeeAbsenceTypes: state.employeeAbsenceTypes })
const employeesConnect = connect(mapEmployeesConnector, employeesDispatch)

const employeesConnectLoading = withLoading(TableEmployeeAbsencesRequests, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }, { fetchUrl: '/api/app/workingCalendar/employeeAbsence/getAllEmployeeAbsenceTypes', propName: 'employeeAbsenceTypes', setFunctionName: 'setEmployeeAbsenceTypes' }])

const TableEmployeeAbsencesRequestsBagConnect = employeesConnect(employeesConnectLoading)

export default EmployeeAbsencesRequests