import { useParams } from 'react-router'
import { TaskCFieldModelType } from '@teinor/erp/types/company/task/taskCField'
import ModalCETaskCustomField from './ModalCETaskCustomField'
import TaskCustomFieldValues from './TaskCustomFieldValues'
import ModalDeleteTaskCustomField from './ModalDeleteTaskCustomField/indext'
import { useMemo } from 'react'
import TaskCustomFieldSortButton from './TaskCustomFieldSortButton'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import DoubleTable from '../../../../../../baseComponents/DoubleTable'
import { useAddDoubleTableGreenRowRender } from '../../../../../../baseComponents/DoubleTable/functions'
import { permissionCheck } from '../../../../../../baseComponents/PermissionChecker/function'
import { TwinTableLink } from '../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { CustomRenderBoolean, EditRowNotLink, DeleteRow } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'

interface TaskCustomFieldsProps extends ComponentWithPermissions { }

const TaskCustomFields: React.FC<TaskCustomFieldsProps> = ({ userPermissions }) => {
    const { TaskCFieldId } = useParams()
    return (<DoubleTable userPermissions={userPermissions} FirstChild={TaskCustomFieldsInner} SecondChild={TaskCustomFieldValues} displaySecond={TaskCFieldId !== 'main'} />)
}


const TaskCustomFieldsInner: React.FC<TaskCustomFieldsProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('customFields', 'Campos personalizados'),
        newText: t('newCustomField', 'Nueva campo personalizado'),
    }
    return (
        <BaseScreenTable TableComponent={TableCustomField} CreateModalComponent={ModalCETaskCustomField} EditModalComponent={ModalCETaskCustomField} DeleteModalComponent={ModalDeleteTaskCustomField} pageData={pageData} userPermissions={userPermissions} />
    )
}

interface TableCustomFieldProps extends TableComponentProps { }

const TableCustomField: React.FC<TableCustomFieldProps> = ({ tableInstance, setTableInstance, userPermissions, setOpened }) => {
    const { t } = useTwinTranslation()
    const { TaskCFieldId } = useParams()
    const { rowRenderer } = useAddDoubleTableGreenRowRender(TaskCFieldId || '')

    const columns: ColumnTableSchema<TaskCFieldModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            sortable: true,
            width: 75
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            }
        },
        {
            id: 'order',
            dataKey: 'order',
            label: t('order', 'Orden'),
            sortable: true,
        },
        {
            id: 'active',
            dataKey: 'active',
            label: t('active', 'Activo'),
            customRender: (parameterValue) => <CustomRenderBoolean value={String(parameterValue) === 'true'} width={45} />,
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRowNotLink key={'edit' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'edit', allRowData })} />
        },
        {
            id: 'delete',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('delete', 'Eliminar'),
            customRender: (parameterValue, allRowData) => <DeleteRow key={'delete' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'delete', allRowData })} />,
            havePermission: permissionCheck(userPermissions, 'delete')
        }
    ]
    const children = useMemo(() => <TaskCustomFieldSortButton tableInstance={tableInstance} userPermissions={userPermissions} />, [tableInstance, userPermissions])

    return (
        <TwinTableLink createRowDataLink={['/task/customFields', ['$id']]} name='taskCustomFields' columns={columns} getDataFrom='/api/app/task/customField/getAllTaskCFields' setTableInstance={setTableInstance} rowRenderer={rowRenderer} defaultOrder={{ defaultOrderField: 'order', order: 'asc' }} hideLoadFiltersButton={true} hideChangeColumnsButton={true} >
            {children}
        </TwinTableLink>
    )
}


export default TaskCustomFields