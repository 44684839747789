import { CustomerTypesInversedKeys } from '@teinor/erp/types/company/customer/customerTypes'
import { TwinDictionary } from '../../globals/dictionary'
import useTwinTranslation from '../useTwinTranslation'


const useCustomerTypesDict = () => {
    const { t } = useTwinTranslation()
    const dictCustomerTypeBase: Record<CustomerTypesInversedKeys, TwinDictionary> = {
        0: { name: t('B2C', 'B2C') },
        1: { name: t('B2B', 'B2B') },
        2: { name: t('lead', 'Lead') }
    }
    const dictCustomerType: TwinDictionary = dictCustomerTypeBase
    return { dictCustomerType }
}

export default useCustomerTypesDict